import { useState, useEffect } from 'react'
import {
  Button,
  Grid,
  Form,
  Card,
  List,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { useGlobalForm } from '../../Context/GlobalFormStateContext'
import {
  SET_FIELD,
  ActualStatus,
  COMMENTS,
} from '../../../globalConfig/common/ConstantData'

export const Comments = ({
  actualComments,
  formik,
  userProfile,
  saveComment,
  setActualStatus,
}) => {
  const [state, dispatch] = useGlobalForm()
  const [addNewCommentFlag, setAddNewCommentFlag] = useState(false)
  const [newComment, setNewComment] = useState('')
  const [isNewCommentEnabled, setIsNewCommentEnabled] = useState(false)

  useEffect(() => {
    if (
      state.actualData?.status === ActualStatus.APPROVED &&
      userProfile?.isVendor
    ) {
      setIsNewCommentEnabled(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.actualData?.status, userProfile])

  function handleAdd() {
    let newNotes = []
    newNotes.push({
      actual_id: state.actualData?.actual_id,
      user_id: userProfile?.userId,
      user_name: userProfile?.fullName,
      comments: newComment,
      comment_ts: new Date().toISOString(),
    })

    formik.setFieldValue('comments', newNotes)
    setAddNewCommentFlag(!addNewCommentFlag)
    setNewComment('')
  }

  function saveActivity() {
    // if only work notes are added and saved
    if (state.activityDescr === '') {
      formik.setFieldValue('status', state.actualData?.status)
      formik.setFieldValue('workflow', state.actualData?.workflow)
      formik.setFieldValue('lanId', state.actualData?.assigned_user_id)
      formik.setFieldValue(
        'assignedUserName',
        state.actualData?.assigned_user_name,
      )
      const activityDescription = `${'Added a Work Note '}`
      dispatch({
        type: SET_FIELD,
        payload: [{ id: 'activityDescr', value: activityDescription }],
      })
    }
    setActualStatus(COMMENTS)
    // saving Activity and comment with one call
    saveComment()
  }

  return (
    <Grid.Container className="hc-pt-normal hc-pl-dense">
      <Grid.Container className="hc-pa-normal">
        <Grid.Item xs={12}>
          {!addNewCommentFlag && (
            <Button
              type="primary"
              size="dense"
              onClick={() => {
                setAddNewCommentFlag(!addNewCommentFlag)
              }}
              disabled={isNewCommentEnabled}
            >
              Add Comment
            </Button>
          )}
          {addNewCommentFlag && (
            <Card className="hc-pa-normal">
              <Grid.Item xs={12}>
                <Form.Field
                  type="textarea"
                  placeholder="Enter your note here..."
                  maxLength="500"
                  value={newComment}
                  onChange={(event) => {
                    setNewComment(event.target.value)
                  }}
                  autoFocus
                />
              </Grid.Item>
              <Grid.Container direction="row-reverse">
                <Grid.Item>
                  <Button
                    className="hc-ma-none"
                    type="primary"
                    size="dense"
                    onClick={() => {
                      setAddNewCommentFlag(!addNewCommentFlag)
                      setNewComment('')
                    }}
                  >
                    CANCEL
                  </Button>
                </Grid.Item>
                <Grid.Item>
                  <Button
                    className="hc-ma-none"
                    type="primary"
                    size="dense"
                    disabled={newComment === ''}
                    onClick={() => {
                      dispatch({
                        type: SET_FIELD,
                        payload: [{ id: 'comments', value: true }],
                      })
                      handleAdd()
                      saveActivity()
                    }}
                  >
                    ADD
                  </Button>
                </Grid.Item>
              </Grid.Container>
            </Card>
          )}
        </Grid.Item>
        {actualComments?.length === 0 && (
          <Grid.Item xs={12} className="hc-bg-grey07">
            <Input.Label>No Notes/Comments to display!</Input.Label>
          </Grid.Item>
        )}
        {actualComments?.length !== 0 && (
          <Grid.Item xs={12}>
            <List>
              {[...actualComments].map((comment, index) => (
                <List.Item
                  selected
                  className="hc-mb-dense hc-bg-grey07"
                  key={index}
                >
                  <Input.Label className="hc-fs-caption hc-mt-dense hc-ml-dense">
                    {comment.user_name} added a note on{' '}
                    <DateFormatter
                      format="MM/DD/YYYY hh:mm A"
                      timezone="America/Chicago"
                      date={comment.comment_ts}
                    />{' '}
                  </Input.Label>
                  <Grid.Container className="hc-ma-dense">
                    {comment.comments}
                  </Grid.Container>
                </List.Item>
              ))}
            </List>
          </Grid.Item>
        )}
      </Grid.Container>
    </Grid.Container>
  )
}
