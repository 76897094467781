import { useEnv } from '@praxis/component-runtime-env'
import axios from 'axios'

export const useCommonRequest = () => {
  const env = useEnv()

  const getAssigneeForAdGroup = async (adGroup) => {
    return await axios
      .get(`${env.camsCoreApiUrl}/ad_group?group=${adGroup}&key=${env.apiKey}`)
      .then((res) =>
        res.data.map((tm) => ({
          id: tm.zid,
          value: tm.name,
          label: tm.name,
        })),
      )
  }

  const getAllAssigneeForAdGroup = async () => {
    return await axios
      .get(
        `${env.camsCoreApiUrl}/ad_groups?group=${[
          env.auth.analystRole[0],
          env.auth.siteOpsRole[0],
          env.auth.managerRole[0],
        ]}&key=${env.apiKey}`,
      )
      .then((res) =>
        res.data.map((tm) => ({
          id: tm.zid,
          value: tm.name,
          label: tm.name,
        })),
      )
  }

  return { getAssigneeForAdGroup, getAllAssigneeForAdGroup }
}
