import { useEnv } from '@praxis/component-runtime-env'
import axios from 'axios'
import { useAuth } from '@praxis/component-auth'
import { AttachmentContext } from '../Context/AttachmentContext'
import { useContext } from 'react'
import moment from 'moment'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'
import _ from 'lodash'
import { useGlobalForm } from '../Context/GlobalFormStateContext'

export const useAttachmentRequest = () => {
  const env = useEnv()
  const auth = useAuth()
  const context = useContext(AttachmentContext)
  const makeToast = ToastProvider.useToaster()
  // eslint-disable-next-line
  const [state, dispatch] = useGlobalForm()

  const attachmentClient = axios.create({
    baseURL: `${env.camsCoreApiUrl}/attachment`,
    // params: { key: env.apiKey },
    headers: {
      'Content-Type': 'multipart/form-data',
      'x-api-key': env.apiKey,
      Authorization: `Bearer ${auth?.session?.accessToken}`,
    },
  })

  const uploadAttachments = async (requestId, vendorId, documentType) => {
    let attachCopy = _.cloneDeep(context.uploadedAttachments)
    attachCopy = _.orderBy(attachCopy, ['name'], ['asc'])
    attachCopy?.forEach(async (attachment, index) => {
      attachCopy[index].uploading = true
      context.setUploadedAttachments([...attachCopy])
      let metadata = {
        vendor_id: vendorId,
        request_id: requestId,
        document_type: documentType,
      }
      let formData = new FormData()
      formData.append('file', attachment?.file)
      formData.append('metadata', JSON.stringify(metadata))
      formData.append('create_user', auth.session?.userInfo?.fullName ?? '')
      await attachmentClient
        .post('', formData)
        .then(() => {
          attachCopy[index].status = 'success'
          attachCopy[index].uploading = false
          context.setUploadedAttachments([...attachCopy])
        })
        .catch((e) => {
          attachCopy[index].status = 'error'
          attachCopy[index].uploading = false
          if (e.response.status === 413) {
            attachCopy[index].errorMessage =
              'File size exceeds allowed size limit. Please upload a compressed file.'
          }
          context.setUploadedAttachments([...attachCopy])
        })
    })
  }

  const retryAttachment = async (
    requestId,
    vendorId,
    fileName,
    documentType,
  ) => {
    let attachCopy = _.cloneDeep(context.uploadedAttachments)
    attachCopy = _.orderBy(attachCopy, ['name'], ['asc'])
    const file = attachCopy.find((att) => att.name === fileName)
    const index = attachCopy.findIndex((att) => att.name === fileName)
    attachCopy[index].uploading = true
    context.setUploadedAttachments([...attachCopy])
    let metadata = {
      vendor_id: vendorId,
      request_id: requestId,
      document_type: documentType,
    }

    let formData = new FormData()
    formData.append('file', file?.file)
    formData.append('metadata', JSON.stringify(metadata))
    formData.append('create_user', auth.session?.userInfo?.fullName ?? '')
    await attachmentClient
      .post('', formData)
      .then(() => {
        attachCopy[index].status = 'success'
        attachCopy[index].uploading = false
        context.setUploadedAttachments([...attachCopy])
      })
      .catch((e) => {
        console.log('error when uploading the attachment::', e)

        attachCopy[index].status = 'error'
        attachCopy[index].uploading = false
        context.setUploadedAttachments([...attachCopy])
      })
  }

  const getAttachmentForReqId = async (id, docType) => {
    const res = await axios.get(
      `${env.camsCoreApiUrl}/request_attachment/id/${id}/type/${docType}?key=${env.apiKey}`,
    )
    return res.data
  }

  // Function to convert bytes to megabytes
  function bytesToMB(bytes) {
    return (bytes / 1000000).toFixed(2) // Convert and round to 2 decimal places
  }

  async function handleAttachments(files) {
    const fileSizeLimit = bytesToMB(env.attachments.maxSize)
    let currentAttachments = context.uploadedAttachments

    // Combine current uploaded files and saved attachments to check for duplicates
    const allExistingFileNames = [
      ...context.uploadedAttachments.map((attachment) => attachment.name),
      ...state.savedAttachments.map((attachment) => attachment.fileName),
    ]

    for (var i = 0; i < files.length; i++) {
      const fileName = files?.item(i).name
      // Check for duplicate in both uploaded attachments and saved attachments
      let duplicate = allExistingFileNames.includes(fileName)

      if (!duplicate) {
        if (files?.item(i)?.size > env.attachments.maxSize) {
          makeToast({
            autoHideDuration: 7000,
            type: 'error',
            heading: 'File size exceeds allowed size limit',
            message: `${
              files?.item(i).name
            }'s size exceeds ${fileSizeLimit} MB limit, please upload a compressed file.`,
          })
          continue
        } else {
          currentAttachments.push({
            name: files?.item(i).name,
            file: files?.item(i),
            uploadDate: moment().format('MM/DD/YYYY'),
            uploading: false,
            status: null,
            errorMessage: null,
          })
        }
      } else {
        // Show a message if the file already exists
        makeToast({
          autoHideDuration: 5000,
          type: 'alert',
          heading: 'Duplicate File',
          message: `The file "${fileName}" already exists.`,
        })
      }
    }
    currentAttachments = _.orderBy(currentAttachments, ['name'], ['asc'])
    context.setUploadedAttachments([...currentAttachments])
  }

  async function removeAttachmentFromList(removeFile) {
    let files = context.uploadedAttachments.filter(
      (file) => file !== removeFile,
    )
    context.setUploadedAttachments(files)
  }
  return {
    getAttachmentForReqId,
    uploadAttachments,
    handleAttachments,
    removeAttachmentFromList,
    retryAttachment,
  }
}
