import React from 'react'
import {
  Table,
  GridContainer,
  GridItem,
  Caption,
  Heading,
  Chip,
} from '@enterprise-ui/canvas-ui-react'
import { useNavigate } from 'react-router-dom'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { useProfile } from '../../../globalComponents/UserProfileProvider'
import {
  BudgetStatus,
  Workflow,
} from '../../../globalConfig/common/ConstantData'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { getStatusColor } from '../utils'
import moment from 'moment'

const style = css`
  max-width: 200px;
  overflow: hidden;
`

export const VendorInfo = ({ vendorName, vendorId }) => {
  return (
    <div css={style} className="hc-to-ellipsis hc-ws-no-wrap">
      {vendorId ? `${vendorId} - ${vendorName}` : 'NA'}
    </div>
  )
}

export const ContractInfo = ({ contractName, contractNo }) => {
  return (
    <div css={style} className="hc-to-ellipsis hc-ws-no-wrap">
      {contractNo ? `${contractNo} - ${contractName}` : 'NA'}
    </div>
  )
}

export const LocationInfo = ({ locationName, locationId }) => {
  return (
    <div css={style} className="hc-to-ellipsis hc-ws-no-wrap">
      {locationId ? `${locationId} - ${locationName}` : 'NA'}
    </div>
  )
}

export function formatText(value, valueType) {
  switch (valueType) {
    case 'date':
      return value ? <DateFormatter date={value} format="L" /> : 'NA'
    case 'amount': {
      const amount = value ?? 0.0
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(Number(amount))
    }
    case 'text':
      return value ?? 'NA'
    default:
      return value
  }
}

export function formatStatus(value) {
  switch (value) {
    case BudgetStatus.MORE_INFO_REQUIRED:
      return 'More Info Required'
    case BudgetStatus.APPROVED_BY_SITEOPS:
      return 'Approved By SiteOps'
    case BudgetStatus.NOT_STARTED:
      return 'Not Started'
    case BudgetStatus.IN_PROGRESS:
      return 'In Progress'
    case BudgetStatus.SUBMITTED_FOR_APPROVAL:
      return 'Submitted For Approval'
    default:
      return value
  }
}
export const BudgetSearchItem = ({ searchResult }) => {
  const navigate = useNavigate()
  const { userProfile } = useProfile()
  // const [state, dispatch] = useGlobalForm()

  return (
    <Table.Row
      key={searchResult?.budget_id}
      onClick={() => navigate(`/budget/${searchResult?.budget_id}`)}
    >
      <Table.Data xs={12} className="hc-pt-none hc-pb-none hc-pr-none">
        <div className="hc-pa-dense">
          <GridContainer spacing="dense">
            <GridItem xs={12}>
              <GridContainer spacing="dense">
                <GridItem className="hc-pt-none" xs={6}>
                  <div style={{ display: 'flex' }}>
                    <Caption>
                      <Heading size={6} className="hc-lh-dense">
                        BUDGET-{searchResult?.budget_id}
                        <Chip
                          className="hc-ma-dense"
                          style={{ backgroundColor: '#79cc0c' }}
                          size="dense"
                        >
                          <strong>{searchResult?.year}</strong>
                        </Chip>
                      </Heading>
                    </Caption>
                  </div>
                </GridItem>
                <GridItem className="hc-ta-right hc-mt-dense" xs={6}>
                  {!userProfile?.isVendor && (
                    <React.Fragment>
                      {searchResult?.workflow !== Workflow.VENDOR && (
                        <Chip
                          size="dense"
                          className="hc-clr-contrast-weak"
                          style={{ backgroundColor: '#4628ed' }}
                        >
                          {searchResult?.assigned_user_name ?? 'Unassigned'}
                        </Chip>
                      )}
                      <Chip size="dense" className="hc-bg-grey04">
                        {searchResult?.workflow}
                      </Chip>
                    </React.Fragment>
                  )}

                  <Chip
                    size="dense"
                    color={getStatusColor(searchResult?.status)}
                    className="hc-txt-uppercase"
                  >
                    {formatStatus(searchResult?.status)}
                  </Chip>
                </GridItem>
                <GridItem xs={12}>
                  <GridContainer spacing="dense">
                    <React.Fragment>
                      <GridItem>
                        <Caption above="Contract No" className="hc-to-ellipsis">
                          <ContractInfo
                            contractNo={searchResult?.contract_no}
                            contractName={searchResult?.contract_name}
                          />
                        </Caption>
                      </GridItem>
                      <GridItem>
                        <Caption above="Vendor" className="hc-to-ellipsis">
                          <VendorInfo
                            vendorId={searchResult?.vendor_no}
                            vendorName={searchResult?.vendor_name}
                          />
                        </Caption>
                      </GridItem>
                      <GridItem>
                        <Caption above="Location" className="hc-to-ellipsis">
                          <LocationInfo
                            locationId={searchResult?.location_id}
                            locationName={searchResult?.loc_name}
                          />
                        </Caption>
                      </GridItem>
                      <GridItem>
                        <Caption above="Total Submitted Expense">
                          {formatText(searchResult?.total_amount, 'amount')}
                        </Caption>
                      </GridItem>
                      <GridItem>
                        <Caption above="Target Share-Annual">
                          {formatText(
                            searchResult?.tgt_annual_amount,
                            'amount',
                          )}
                        </Caption>
                      </GridItem>
                      <GridItem>
                        <Caption above="Target Share-Monthly">
                          {formatText(
                            searchResult?.tgt_monthly_amount,
                            'amount',
                          )}
                        </Caption>
                      </GridItem>
                      <GridItem>
                        <Caption above="Create Date">
                          {moment(searchResult?.create_ts).format('MM/DD/YYYY')}
                        </Caption>
                      </GridItem>
                      {searchResult?.update_ts !== null && (
                        <GridItem>
                          <Caption above="Update Date">
                            {moment(searchResult?.update_ts).format(
                              'MM/DD/YYYY',
                            )}
                          </Caption>
                        </GridItem>
                      )}
                    </React.Fragment>
                    {/* )} */}
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      </Table.Data>
    </Table.Row>
  )
}
