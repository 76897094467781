import { Document, Page, Text, View, Image } from '@react-pdf/renderer'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import moment from 'moment-timezone'
import targetLogo from './../../../icons/target-logo.png'
import { styles } from '../PDFExports/Styles'
import {
  currencyFormatter,
  amountFormatter,
} from '../../Utils/NumericalFormatter'
import ActualTableHeader from './ActualTableHeader'

export const ActualSettlementReportToPDF = ({ title, data, session }) => {
  // Create a map of expense_type to gl_account from expense_pool
  const expenseTypeToGlAccountMap = new Map(
    data?.expensePool.map((expense) => [
      expense.expense_type,
      expense.gl_account,
    ]),
  )

  // Add gl_account to each actual_expense
  const actualExpensesWithGlAccount = data?.actualData?.actual_expenses?.map(
    (expense) => ({
      ...expense,
      gl_account: expenseTypeToGlAccountMap.get(expense.expense_type) || 'N/A', // Add gl_account or 'N/A' if not found
    }),
  )

  const headerInfo = {
    'Fiscal Year:': data.actualData?.year,
    'Contract No:': data.actualData?.contract_no,
    'Location Id:': data.actualData?.location_id,
    'Location Name:': data.actualData?.loc_name,
    'Vendor #:': data.actualData?.vendor_no,
    'Vendor Name': data.actualData?.vendor_name,
    'Total Submitted Expense': currencyFormatter(data.actualData?.total_amount),
    'Target Share - Annual': currencyFormatter(
      data.actualData?.tgt_annual_amount,
    ),
    'Tax Rate %':
      data.actualData?.tax_rate !== null
        ? amountFormatter(data.actualData?.tax_rate)
        : currencyFormatter(0),
    'Tax Amount':
      data.actualData?.tax_amount !== null
        ? currencyFormatter(data.actualData?.tax_amount)
        : currencyFormatter(0),
    'Target Share Annual - Incl. Taxes': currencyFormatter(
      data.actualData?.total_due_amount,
    ),
    'Prior Payment Amount':
      data.actualData?.prior_payment_amount !== null
        ? currencyFormatter(data.actualData?.prior_payment_amount)
        : currencyFormatter(0),
    'Prior Tax Payment Amount':
      data.actualData?.prior_tax_payment_amount !== null
        ? currencyFormatter(data.actualData?.prior_tax_payment_amount)
        : currencyFormatter(0),
    'Final Due Amount': currencyFormatter(data.actualData?.final_due_amount),
  }

  return (
    <Document title={title} author="CAM" key={title}>
      <Page style={styles.page} wrap orientation="landscape">
        <View fixed style={styles.brandingHeader}>
          <View style={styles.brandingBox}>
            <View style={styles.brandingLogo}>
              <Image
                src={targetLogo}
                style={{ height: '30px', width: '30px' }}
              ></Image>
            </View>
            <View style={styles.brandingTitle}>
              <Text>CAM</Text>
            </View>
          </View>
        </View>
        <View style={styles.topsection}>
          <Text style={styles.heading}>{title}</Text>
          <View style={styles.table}>
            {Object.entries(headerInfo).map(([key, value]) => (
              <View style={styles.tableRow}>
                <Text style={styles.headerInfo}>{key}</Text>
                <Text style={styles.headerValue}>{value}</Text>
              </View>
            ))}
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Expense Details</Text>
          <>
            <View
              style={{
                borderTopWidth: '2px',
                borderTopColor: 'rgba(191, 191, 191, 1)',
              }}
              fixed
            ></View>
            <View style={styles.table}>
              <ActualTableHeader fixed />
              <View
                style={{
                  borderTopWidth: '2px',
                  borderTopColor: 'rgba(191, 191, 191, 1)',
                }}
                fixed
              ></View>
              {actualExpensesWithGlAccount?.map((rowDetails, i) => (
                <>
                  <View style={styles.tableRow} wrap={false}>
                    <Text
                      style={{
                        width: '110px',
                        textAlign: 'left',
                        fontSize: '9px',
                        padding: '7px',
                      }}
                    >
                      {rowDetails.expense_type}
                    </Text>
                    <Text
                      style={{
                        width: '150px',
                        textAlign: 'left',
                        fontSize: '9px',
                        padding: '7px',
                      }}
                    >
                      {rowDetails.expense_type_name}
                    </Text>
                    <Text
                      style={{
                        width: '100px',
                        textAlign: 'right',
                        fontSize: '9px',
                        padding: '7px',
                      }}
                    >
                      {rowDetails.gl_account}
                    </Text>
                    <Text
                      style={{
                        width: '150px',
                        textAlign: 'right',
                        fontSize: '9px',
                        padding: '7px',
                      }}
                    >
                      {currencyFormatter(rowDetails.total_expense_amount)}
                    </Text>
                    <Text
                      style={{
                        width: '80px',
                        textAlign: 'right',
                        fontSize: '9px',
                        padding: '7px',
                      }}
                    >
                      {amountFormatter(rowDetails.prs)}
                    </Text>
                    <Text
                      style={{
                        width: '150px',
                        textAlign: 'right',
                        fontSize: '9px',
                        padding: '7px',
                      }}
                    >
                      {currencyFormatter(rowDetails.prs_amount)}
                    </Text>
                    <Text
                      style={{
                        width: '80px',
                        textAlign: 'right',
                        fontSize: '9px',
                        padding: '7px',
                      }}
                    >
                      {amountFormatter(rowDetails.admin_fee)}
                    </Text>
                    <Text
                      style={{
                        width: '150px',
                        textAlign: 'right',
                        fontSize: '9px',
                        padding: '7px',
                      }}
                    >
                      {currencyFormatter(rowDetails.admin_amount)}
                    </Text>
                    <Text
                      style={{
                        width: '150px',
                        textAlign: 'right',
                        fontSize: '9px',
                        padding: '7px',
                      }}
                    >
                      {currencyFormatter(rowDetails.annual_amount)}
                    </Text>
                  </View>
                  <View
                    style={{
                      borderTopWidth: '1px',
                      borderTopColor: 'rgba(191, 191, 191, 1)',
                    }}
                  ></View>
                </>
              ))}
              {/* Total */}
              <View style={styles.tableRow} wrap={false}>
                <Text
                  style={{
                    width: '110px',
                    textAlign: 'left',
                    fontSize: '10px',
                    fontFamily: 'Helvetica-Bold',
                    padding: '7px',
                  }}
                >
                  {'Total'}
                </Text>
                <Text
                  style={{
                    width: '150px',
                    textAlign: 'right',
                    fontSize: '9px',
                    padding: '7px',
                  }}
                >
                  {''}
                </Text>
                <Text
                  style={{
                    width: '100px',
                    textAlign: 'right',
                    fontSize: '9px',
                    padding: '7px',
                  }}
                >
                  {''}
                </Text>
                <Text
                  style={{
                    width: '150px',
                    textAlign: 'right',
                    fontSize: '9px',
                    padding: '7px',
                  }}
                >
                  {currencyFormatter(data.grandTotalExpenseAmount)}
                </Text>
                <Text
                  style={{
                    width: '80px',
                    textAlign: 'right',
                    fontSize: '9px',
                    padding: '7px',
                  }}
                >
                  {''}
                </Text>
                <Text
                  style={{
                    width: '150px',
                    textAlign: 'right',
                    fontSize: '9px',
                    padding: '7px',
                  }}
                >
                  {currencyFormatter(data.totalPrsAmount)}
                </Text>
                <Text
                  style={{
                    width: '80px',
                    textAlign: 'right',
                    fontSize: '9px',
                    padding: '7px',
                  }}
                >
                  {''}
                </Text>
                <Text
                  style={{
                    width: '150px',
                    textAlign: 'right',
                    fontSize: '9px',
                    padding: '7px',
                  }}
                >
                  {currencyFormatter(data.totalAdminAmount)}
                </Text>
                <Text
                  style={{
                    width: '150px',
                    textAlign: 'right',
                    fontSize: '9px',
                    padding: '7px',
                  }}
                >
                  {currencyFormatter(data.totalAnnualAmount)}
                </Text>
              </View>
              <View
                style={{
                  borderTopWidth: '2px',
                  borderTopColor: 'rgba(191, 191, 191, 1)',
                }}
              ></View>
            </View>
            <Text style={{ paddingBottom: '20px' }}></Text>
          </>
        </View>
        <View style={styles.exportMetadata} fixed>
          <Text style={{ textAlign: 'center' }}>
            Downloaded by {session?.userInfo.fullName} on{' '}
            <DateFormatter
              date={moment(new Date())}
              timezone="America/Chicago"
            />
          </Text>
        </View>
      </Page>
    </Document>
  )
}
