import {
  Grid,
  Heading,
  Input,
  Modal,
  Card,
  Button,
  Spinner,
  useToaster,
  ProgressBar,
} from '@enterprise-ui/canvas-ui-react'
import React, { useState, useRef } from 'react'
import { useGlobalForm } from '../../Context/GlobalFormStateContext'
import { SET_FIELD } from '../../../globalConfig/common/ConstantData'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-theme-balham.css'
import ViewAttachment from './ViewAttachment'
import DownloadDocument from './DownloadDocument'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Gallery } from '@enterprise-ui/canvas-ui-react-gallery'
import axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import { useAuth } from '@praxis/component-auth'
import jsFileDownload from 'js-file-download'
import { DownloadIcon, EnterpriseIcon } from '@enterprise-ui/icons'
import download from 'downloadjs'

const modalBackground = css`
  .C-Overlay.\--background-dark {
    background: #00000014;
  }
`

const agGrid = css`
  .ag-cell {
    font-size: 10px !important;
  }
  .ag-header-cell-text {
    font-size: 10px !important;
  }
`
const DownloadAttachments = () => {
  const auth = useAuth()
  const env = useEnv()
  const [state, dispatch] = useGlobalForm()
  const makeToast = useToaster()
  const [selectedRowCount, setSelectedRowCount] = useState(0)
  const [filesSelected, setFilesSelected] = useState([])
  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadPercentCompleted, setDownloadPercentageComplete] = useState(0)
  const gridApi = useRef(null)

  const downloadAttachment = async (attachmentId, filename) => {
    return await axios
      .get(`${env.camsCoreApiUrl}/attachment/${attachmentId}`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-api-key': `${env?.apiKey}`,
          Authorization: `Bearer ${auth?.session?.accessToken}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          jsFileDownload(res.data, filename)
        }
      })
      .catch((reason) => {
        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'downloadingAttachment', value: undefined },
            { id: 'attachmentsError', value: true },
          ],
        })
        makeToast({
          type: 'error',
          heading: 'Error Downloading',
          message: 'There was an error downloading the file. Please try again',
        })
      })
  }

  const onSelectionChanged = (event) => {
    setSelectedRowCount(event.api.getSelectedRows().length)
  }

  const handleDownload = async () => {
    await dispatch({
      type: SET_FIELD,
      payload: [{ id: 'downloadingAttachment', value: state.fileRowIndex }],
    })
    let allFiles = state.savedAttachments ? state.savedAttachments : []
    let attachment = allFiles[state.fileRowIndex]
    await downloadAttachment(
      attachment['attachmentId'],
      attachment['fileName'],
    ).then(async () => {
      await dispatch({
        type: SET_FIELD,
        payload: [{ id: 'downloadingAttachment', value: undefined }],
      })
    })
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const downloadMultipleFiles = async () => {
    let totalFiles = filesSelected.length
    setIsDownloading(true)
    let count = 0
    let zip = require('jszip')()
    let percentCompleted = 0
    setDownloadPercentageComplete(percentCompleted)
    let fileFolderName = `${state.actualData?.location_id}-${state.actualData?.year}-${state.actualData?.contract_no}-payable-actual`

    const downloadPromises = filesSelected.map((eachFile) => {
      return axios
        .get(`${env.camsCoreApiUrl}/attachment/${eachFile.attachmentId}`, {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-api-key': `${env?.apiKey}`,
            Authorization: `Bearer ${auth?.session?.accessToken}`,
          },
        })
        .then((res) => {
          zip.file(eachFile.fileName, res.data, { binary: true })
          count++
          percentCompleted = Math.round((count * 100) / totalFiles)
          setDownloadPercentageComplete(percentCompleted)
        })
        .catch((err) => {
          console.log('Error =', err)
        })
    })

    Promise.all(downloadPromises)
      .then(() => {
        return zip.generateAsync({ type: 'blob', compression: 'DEFLATE' })
      })
      .then((content) => {
        download(content, fileFolderName)
        setIsDownloading(false)
        setFilesSelected([])
        gridApi.current.deselectAll() // Deselect all rows
      })
      .catch((err) => {
        console.error('Error generating zip file:', err)
        setIsDownloading(false)
      })
  }

  const [columnDefs] = useState([
    {
      headerName: 'File Name',
      field: 'fileName',
      width: 120,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      headerName: 'Download',
      field: 'attachmentId',
      width: 80,
      cellRenderer: 'downloadDocument',
    },
    {
      headerName: 'View',
      field: 'attachmentId',
      width: 80,
      cellRenderer: 'viewAttachment',
    },
    {
      headerName: 'Created By',
      field: 'createdBy',
      width: 110,
    },
    {
      headerName: 'Created Date',
      field: 'createdTimestamp',
      width: 110,
    },
  ])

  return (
    <React.Fragment>
      <div className="hc-pa-normal hc-pl-none hc-pr-none hc-pt-none">
        <Heading size={6}>Available Backup Documentation:</Heading>
        <Grid.Container spacing="none">
          <Grid.Item xs={12} className="hc-ml-none hc-mt-expanded">
            {state.loadAttachmentList && (
              <Spinner size="dense" className="hc-ma-normal" />
            )}
            {!state.loadAttachmentList &&
              (!state.savedAttachments ||
                state.savedAttachments.length === 0) && (
                <Grid.Container className="hc-pt-none hc-ml-normal">
                  <Grid.Item className="hc-pt-dense hc-pb-dense">
                    <Input.Label>No Documents Attached!</Input.Label>
                  </Grid.Item>
                </Grid.Container>
              )}
            {state.savedAttachments && state.savedAttachments.length > 0 && (
              <div className="ag-theme-balham">
                <AgGridReact
                  frameworkComponents={{
                    viewAttachment: ViewAttachment,
                    downloadDocument: DownloadDocument,
                  }}
                  css={agGrid}
                  columnDefs={columnDefs}
                  defaultColDef={{
                    autoHeight: true,
                    sortable: true,
                    flex: 1,
                    filter: true,
                    resizable: true,
                  }}
                  pagination={true}
                  rowSelection="multiple"
                  onSelectionChanged={onSelectionChanged}
                  onRowSelected={(params) => {
                    setFilesSelected(params.api.getSelectedRows())
                  }}
                  onGridReady={onGridReady}
                  paginationPageSize={10}
                  rowData={state.savedAttachments}
                  domLayout={'autoHeight'}
                ></AgGridReact>
              </div>
            )}
          </Grid.Item>
        </Grid.Container>
      </div>
      {selectedRowCount > 0 && (
        <div className="hc-pa-normal hc-pb-dense">
          <Grid.Container direction="row-reverse">
            <Grid.Item>
              <Button type="primary" onClick={downloadMultipleFiles}>
                Download
              </Button>
            </Grid.Item>
          </Grid.Container>
        </div>
      )}
      {isDownloading && (
        <>
          <Grid.Item className="hc-pt-md hc-ta-center">
            <Heading size={5}>Downloading...</Heading>
            <ProgressBar percentComplete={downloadPercentCompleted} />
          </Grid.Item>
        </>
      )}
      <Modal
        css={modalBackground}
        isVisible={state.setAttachmentView}
        size={state.isAttachmentNotViewable ? 'dense' : 'normal'}
        onRefuse={() =>
          dispatch({
            type: SET_FIELD,
            payload: [
              { id: 'setAttachmentView', value: false },
              { id: 'fileRowIndex', value: undefined },
            ],
          })
        }
      >
        <div className="hc-pa-normal">
          {state.format && (
            <Gallery
              onDownload={() => {
                handleDownload()
              }}
              className="hc-bg-white hc-ma-lg"
              mainItemHeight="70vh"
              items={[
                {
                  mediaType: state.mediaType,
                  name: state.fileName,
                  url: state.fileUrl,
                },
              ]}
            />
          )}
          {state.isAttachmentNotViewable && (
            <Card
              className="hc-pa-normal hc-bg-grey02"
              align="center"
              justify="center"
              elevation={5}
            >
              <div className="hc-pa-normal hc-ta-center">
                <p className="hc-clr-grey05">
                  This file format is not viewable. Please Download
                  <Button
                    className="hc-ml-normal"
                    xs={1}
                    onClick={() => {
                      handleDownload()
                    }}
                    isLoading={
                      state.downloadingAttachment === state.fileRowIndex
                    }
                  >
                    <EnterpriseIcon icon={DownloadIcon} />
                  </Button>
                </p>
              </div>
            </Card>
          )}
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default DownloadAttachments
