import React, { useState } from 'react'
import axios from 'axios'
import { useCaseSearch } from '../SearchContext/SearchProvider'
import qs from 'qs'
import { GridContainer, GridItem } from '@enterprise-ui/canvas-ui-react'
import { useLocation } from 'react-router-dom'
import { UPDATE_FILTER } from '../SearchContext/ActionTypes'
import { BudgetSearchTable } from './BudgetSearchTable'
import { buildQuery } from '../SearchContext/SearchUtils'
import { useProfile } from '../../../globalComponents/UserProfileProvider'
import _ from 'lodash'
import { useEnv } from '@praxis/component-runtime-env'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'

export const BudgetSearch = (props) => {
  const env = useEnv()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [budgetStatusCount, setBudgetStatusCount] = useState([])
  const makeToast = ToastProvider.useToaster()

  const [sortColumn, setSortColumn] = useState({
    id: 'createTs',
    value: 'Create Date',
  })

  const [sortAscending, setSortAscending] = useState(false)
  const fetchIdRef = React.useRef(0)
  const [state, dispatch] = useCaseSearch()
  const { userProfile } = useProfile()
  const location = useLocation()

  const fetchBudget = React.useCallback(
    async ({ pageSize, pageIndex, sortColumn, sortAscending }) => {
      const fetchId = ++fetchIdRef.current

      if (fetchId === fetchIdRef.current) {
        setLoading(true)
        try {
          let query =
            buildQuery(
              location,
              state,
              userProfile.isVendor,
              userProfile.userId,
              'budget',
            ) ?? {}

          if (
            userProfile?.isVendor
            //  && query['vendorNo'] == undefined
          ) {
            query['vendor_no'] = userProfile.vendorList?.map(
              (vendor) => vendor?.vendorNo,
            )
          }

          if (query['location_id'] !== null) {
            if (query['location_id']?.length === 4) {
              query['location_id'] = 'T' + query['location_id']
            }
          }

          let queryForStatusCount = query

          query['page'] = pageIndex + 1
          query['per_page'] = pageSize
          query['sort_column'] = sortColumn.id
          query['sort_order'] = sortAscending ? 'ASC' : 'DESC'

          //api call for getting aggregation status of Status in the budget
          const statusCountResponse =
            await getBudgetsStatusCountForAppliedFilters(queryForStatusCount)

          setBudgetStatusCount(statusCountResponse.data ?? [])

          //api call
          const response = await getBudgetsForAppliedFilters(query)

          setData(response.data?.content ?? [])
          setTotalPages(response.data?.total_pages || 0)
          setTotalRows(response.data?.total_count || 0)
          setLoading(false)
        } catch (error) {
          console.log('Error in budget search API', error)
          setLoading(false)
          makeToast({
            autoHideDuration: 4000,
            type: 'error',
            heading: 'Error while fetching budgets',
            message:
              'Make sure you are connected to network or try again later',
          })
          //TODO Toaster message
          //TODO Loading spinner
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [state, userProfile],
  )

  const getBudgetsForAppliedFilters = async (filtersApplied = {}) => {
    let formattedFilters = _.omitBy(filtersApplied, (v) => v == null)
    let urlParam = Object.entries(formattedFilters)
      .map((e) => e.join('='))
      .join('&')
    const res = await axios.get(
      `${env.camsCoreApiUrl}/budgets?key=${env.apiKey}&${urlParam}`,
    )
    return res
  }

  const getBudgetsStatusCountForAppliedFilters = async (
    filtersApplied = {},
  ) => {
    let formattedFilters = _.omitBy(filtersApplied, (v) => v == null)
    let urlParam = Object.entries(formattedFilters)
      .map((e) => e.join('='))
      .join('&')
    const res = await axios.get(
      `${env.camsCoreApiUrl}/budget_aggregations/status?key=${env.apiKey}&${urlParam}`,
    )
    return res
  }

  React.useEffect(() => {
    dispatch({
      type: UPDATE_FILTER,
      payload: qs.parse(location.search.replace('?', ''), {
        arrayLimit: 100,
      }),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.search])

  return (
    <GridContainer>
      <GridItem xs={12}>
        <BudgetSearchTable
          data={data}
          budgetStatusCount={budgetStatusCount}
          loading={loading}
          totalPages={totalPages}
          totalCases={totalRows}
          loadBudgets={fetchBudget}
          sortColumn={sortColumn}
          setSortColumn={setSortColumn}
          sortAscending={sortAscending}
          setSortAscending={setSortAscending}
        ></BudgetSearchTable>
      </GridItem>
    </GridContainer>
  )
}
