import { useState, useEffect } from 'react'
import {
  ExpandableSection,
  Heading,
  Input,
} from '@enterprise-ui/canvas-ui-react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FilterCheckBox } from './FilterCheckBox'
import { FilterSearch } from './FilterSearch'
import { FilterDate } from './FilterDate'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'

const filterHeight = css`
  max-height: 170px;
  overflow-y: auto;
`
const autoSizeHeight = css`
  height: 170px;
`
export const Filter = ({
  label,
  id,
  filters,
  onUpdate,
  searchable,
  type,
  active,
  startExpanded = true,
  searchFields,
}) => {
  const [list, setList] = useState(filters ? filters : [])
  const [searchActive, setSearchActive] = useState(
    searchable || list?.length > 7,
  )

  useEffect(() => {
    setList(filters ? filters : [])
    setSearchActive(filters?.length > 7)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const handleSearch = (e) => {
    const items = [...filters]

    setList(
      items.filter((item) => {
        if (!item.label) return true
        return item.label.toLowerCase().includes(e.target.value.toLowerCase())
      }),
    )
  }

  if (!filters && type !== 'search_group') return null

  const Row = ({ data, index, style }) => {
    const item = list[index]
    return (
      <div style={style}>
        <FilterCheckBox
          key={item.id}
          id={`${item.id}-${id}`}
          label={item.label}
          value={item.id}
          checked={item.isChecked}
          onChange={(e) => {
            onUpdate(id, 'checkbox', e)
          }}
        />
      </div>
    )
  }

  return (
    <ExpandableSection
      padding="dense"
      startExpanded={startExpanded}
      toggleLocation="left"
    >
      <Heading className="hc-clr-black" size={6}>
        {label}
      </Heading>
      <ExpandableSection.Content className="hc-ml-sm hc-mr-sm">
        {searchActive && (
          <Input.Text
            id={`search_${id}`}
            placeholder={`Search ${label}`}
            onChange={handleSearch}
          />
        )}

        {type === 'checkbox' &&
          (filters && filters?.length > 10 ? (
            <div css={autoSizeHeight}>
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    className="List"
                    height={height}
                    itemCount={list.length}
                    itemSize={30}
                    width={width}
                  >
                    {Row}
                  </List>
                )}
              </AutoSizer>
            </div>
          ) : (
            <div css={filterHeight}>
              {list.map((item) => {
                return (
                  <FilterCheckBox
                    key={item.id}
                    id={`${item.id}-${id}`}
                    label={item.label}
                    value={item.id}
                    checked={item.isChecked}
                    onChange={(e) => {
                      onUpdate(id, 'checkbox', e)
                    }}
                  />
                )
              })}
            </div>
          ))}
        {type === 'date' && (
          <FilterDate
            id={id}
            key={id}
            values={list}
            activeFilter={active}
            onChange={(id, value) => {
              onUpdate(id, 'date', value)
            }}
          />
        )}
        {type === 'radio' && (
          <>
            <Input.Radio
              id={id}
              options={filters}
              onUpdate={(id, value) => {
                onUpdate(id, 'radio', value)
              }}
              value={
                filters?.filter((option) => {
                  return option.isChecked === true
                })[0]?.value
              }
            ></Input.Radio>
          </>
        )}
        {type === 'search_group' &&
          searchFields?.map((field) => (
            <FilterSearch
              id={field.field}
              key={id}
              // suggestField={field.suggestField}
              label={field.label}
              placeHolder={field.placeHolder}
              onUpdate={(searchId, value) => {
                onUpdate(id, 'search_group', value, searchId)
              }}
            />
          ))}
      </ExpandableSection.Content>
    </ExpandableSection>
  )
}
