import { Button } from '@enterprise-ui/canvas-ui-react'
import { useGlobalForm } from '../../Context/GlobalFormStateContext'
import { SET_FIELD } from '../../../globalConfig/common/ConstantData'
import axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import { useAuth } from '@praxis/component-auth'
import jsFileDownload from 'js-file-download'
import { DownloadIcon, EnterpriseIcon } from '@enterprise-ui/icons'

const DownloadDocument = (props) => {
  const auth = useAuth()
  const env = useEnv()
  const [state, dispatch] = useGlobalForm()

  const downloadAttachment = async (attachmentId, filename) => {
    return await axios
      .get(`${env.camsCoreApiUrl}/attachment/${attachmentId}`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-api-key': `${env?.apiKey}`,
          Authorization: `Bearer ${auth?.session?.accessToken}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          jsFileDownload(res.data, filename)
        }
      })
      .catch((reason) => {
        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'downloadingAttachment', value: undefined },
            { id: 'attachmentsError', value: true },
            { id: 'toastHeading', value: 'Error Downloading' },
            {
              id: 'toastMessage',
              value:
                'There was an error downloading the file. Please try again.',
            },
          ],
        })
      })
  }

  const handleDownload = async () => {
    await dispatch({
      type: SET_FIELD,
      payload: [{ id: 'downloadingAttachment', value: props.rowIndex }],
    })
    let allFiles = state.savedAttachments ? state.savedAttachments : []
    let attachment = allFiles[props.rowIndex]
    await downloadAttachment(
      attachment['attachmentId'],
      attachment['fileName'],
    ).then(async () => {
      await dispatch({
        type: SET_FIELD,
        payload: [{ id: 'downloadingAttachment', value: undefined }],
      })
    })
  }
  return (
    <Button
      xs={1}
      iconOnly
      aria-label="Download Attachment"
      type="ghost"
      onClick={() => handleDownload()}
      isLoading={state.downloadingAttachment === props.rowIndex}
    >
      <EnterpriseIcon icon={DownloadIcon} size="inline" />
    </Button>
  )
}

export default DownloadDocument
