import { useEffect } from 'react'
import {
  Card,
  FormField,
  GridContainer,
  GridItem,
  Heading,
  Input,
  Pagination,
  Spinner,
  Table,
  Dropdown,
  Button,
} from '@enterprise-ui/canvas-ui-react'
import { useTable, usePagination, useRowSelect } from 'react-table'
import { BudgetSearchItem } from './BudgetSearchItem'
import { StatusCountLandingCard } from '../StatusCountLandingCard'
import { Security } from '../../../globalConfig/common/ConstantData'
import {
  ArrowDownIcon,
  ArrowUpIcon,
  EnterpriseIcon,
} from '@enterprise-ui/icons'

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import BudgetDownloadSearchResult from './BudgetDownloadSearchResult'
import { useProfile } from '../../../globalComponents/UserProfileProvider'

const baseLayout = css`
  height: 80vh;
  overflow: hidden;
`

const rightContent = css`
  display: flex;
  justify-content: flex-end;
`

const totalAlign = css`
  display: flex;
  align-items: center;
`

const labelBottom = css`
  margin-bottom: 0px;
`
const columns = [
  {
    Header: 'Budget Id',
    accessor: 'budgetId',
  },
]

const sortFields = [
  { id: 'createTs', value: 'Create Date', security: Security.BOTH },
  { id: 'updateTs', value: 'Update Date', security: Security.BOTH },
]

export const BudgetSearchTable = ({
  data = [],
  budgetStatusCount = [],
  loading,
  totalPages,
  totalCases,
  loadBudgets,
  sortColumn,
  setSortColumn,
  sortAscending,
  setSortAscending,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      manualSortBy: false,
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: totalPages,
    },
    usePagination,
    useRowSelect,
  )

  const { userProfile } = useProfile()

  useEffect(() => {
    loadBudgets({
      pageSize: pageSize,
      pageIndex: pageIndex,
      sortColumn: sortColumn,
      sortAscending: sortAscending,
    })
  }, [loadBudgets, pageIndex, pageSize, sortColumn, sortAscending])

  if (loading) {
    return (
      <GridContainer justify="center" align="center" css={baseLayout}>
        <GridItem>
          <Spinner />
        </GridItem>
      </GridContainer>
    )
  }

  return (
    <div>
      <GridContainer justify="space-between" align="center">
        <GridItem xs={12} className="hc-pb-none hc-pt-normal">
          {!userProfile?.isVendor && (
            <StatusCountLandingCard statusCount={budgetStatusCount} />
          )}
          <div css={rightContent} className="hc-mt-expanded hc-mb-normal">
            <div css={totalAlign} className="hc-mr-expanded">
              <Input.Label className="hc-clr-grey01 hc-fs-md" css={labelBottom}>
                Total Budgets: &nbsp;
              </Input.Label>
              <Heading size={5}>{totalCases}</Heading>
            </div>
            <Dropdown>
              <Button type="secondary" className="hc-mr-xs">
                <span className="hc-fs-caption">Sort by: </span>
                &nbsp;
                {sortColumn.value}
              </Button>
              <Dropdown.Menu className="hc-bg-grey04">
                {sortFields
                  // .filter((key) => filterUser(key, userProfile?.isVendor))
                  .map((sort) => (
                    <Dropdown.MenuItem
                      key={sort.id}
                      onClick={() => setSortColumn(sort)}
                    >
                      {sort.value}
                    </Dropdown.MenuItem>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
            <Button
              onClick={() => {
                setSortAscending((sort) => !sort)
              }}
              type="secondary"
              aria-label="Sort"
            >
              <span className="hc-fs-caption">Sort by: </span>
              &nbsp;
              <EnterpriseIcon
                icon={sortAscending ? ArrowDownIcon : ArrowUpIcon}
              />
            </Button>
            <BudgetDownloadSearchResult
              totalCases={totalCases}
              sortColumn={sortColumn}
              sortAscending={sortAscending}
            />
          </div>
        </GridItem>
        <GridItem xs={12}>
          <Card elevation={2}>
            <Table spacing="none" {...getTableProps()}>
              <Table.Body {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <BudgetSearchItem
                      searchResult={row.original}
                      key={row.id}
                    />
                  )
                })}
              </Table.Body>
            </Table>
            <GridContainer justify="space-between" className="hc-pa-md">
              <GridItem xs={6}>
                <Pagination
                  currentPage={pageIndex + 1}
                  totalPages={pageCount}
                  onRequestNext={() => {
                    nextPage()
                  }}
                  onRequestPrev={() => {
                    previousPage()
                  }}
                />
              </GridItem>
              <GridItem xs={2}>
                <FormField
                  id={'pageSize'}
                  type="select"
                  onUpdate={(e, a) => {
                    setPageSize(Number(a))
                  }}
                  options={[
                    { value: 10, label: 'Show 10' },
                    { value: 25, label: 'Show 25' },
                    { value: 50, label: 'Show 50' },
                  ]}
                  value={pageSize}
                ></FormField>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}
