import { Grid, Heading, Form, Input } from '@enterprise-ui/canvas-ui-react'
import '../../Actual/Custom.css'
import { GenericFunctions } from '../../Utils/GenericFunctions'
import { useGlobalForm } from '../../Context/GlobalFormStateContext'

export const ViewActualAmountDetails = ({ formik }) => {
  const { deriveFinalActualAmount, isActualSummaryChanged } = GenericFunctions()
  // eslint-disable-next-line
  const [state] = useGlobalForm()

  const locationHeading = 'Actual Details'

  return (
    <Grid.Container className="hc-pt-expanded hc-ml-min">
      <Heading size={6}>{locationHeading} </Heading>
      <Grid.Container className="hc-pt-expanded hc-mr-normal">
        <Grid.Item xs={10} md={3}>
          <Input.Label htmlFor="total_amount_lbl">
            Total Submitted Expense
          </Input.Label>
          <Input.Currency
            id="totalAmount"
            display={formik.values.currencyType}
            value={formik.values.totalAmount}
            allowDecimals
            disabled
          />
        </Grid.Item>

        <Grid.Item xs={10} md={3}>
          <Input.Label htmlFor="tax_amount_lbl">
            Target Share - Annual
          </Input.Label>
          <Input.Currency
            id="totalAnnualAmount"
            display={formik.values.currencyType}
            value={formik.values.totalAnnualAmount}
            allowDecimals
            disabled
          />
        </Grid.Item>

        <Grid.Item xs={10} md={3}>
          <Form.Field
            id={'taxRate'}
            label={'Tax Rate (%)'}
            type="number"
            className="inputtype"
            disabled={state.isDisplayOnlyActual}
            onChange={(e) => formik.setFieldValue('taxRate', e.target.value)}
            onBlur={(e) => {
              e.preventDefault()
              deriveFinalActualAmount(formik)
              isActualSummaryChanged()
            }}
            value={formik.values.taxRate || ''}
            error={
              formik.touched.taxRate && formik.errors.taxRate !== undefined
            }
            errorText={formik.errors.taxRate}
          />
        </Grid.Item>

        <Grid.Item xs={10} md={3}>
          <Input.Label htmlFor="tax_amount_lbl">Tax Amount</Input.Label>
          <Input.Currency
            id="taxAmount"
            display={formik.values.currencyType}
            value={formik.values.taxAmount}
            allowDecimals
            disabled
          />
        </Grid.Item>
        {/* Target Annual Amount Including Taxes */}
        <Grid.Item xs={10} md={3}>
          <Input.Label htmlFor="total_due_amount_lbl">
            Target Share Annual - Incl. Taxes
          </Input.Label>
          <Input.Currency
            id="totalDueAmount"
            display={formik.values.currencyType}
            value={formik.values.totalDueAmount}
            allowDecimals
            disabled
          />
        </Grid.Item>

        <Grid.Item xs={10} md={3}>
          <Input.Label htmlFor="prior_payment_amount_lbl">
            Prior Payment Amount
          </Input.Label>
          <Input.Currency
            id="priorPaymentAmount"
            display={formik.values.currencyType}
            value={formik.values.priorPaymentAmount}
            disabled={state.isDisplayOnlyActual}
            onChange={(event) => {
              formik.setFieldValue(
                'priorPaymentAmount',
                event.target.value?.replace(/[^0-9.-]+/g, ''),
              )
            }}
            onBlur={(e) => {
              e.preventDefault()
              deriveFinalActualAmount(formik)
              isActualSummaryChanged()
            }}
            allowDecimals
          />
          {formik.errors.priorPaymentAmount !== undefined &&
            formik.touched.priorPaymentAmount && (
              <Input.Label
                htmlFor="prior_payment_amount_err"
                className="hc-clr-error hc-mt-min"
              >
                {formik.errors.priorPaymentAmount}
              </Input.Label>
            )}
        </Grid.Item>

        <Grid.Item xs={10} md={3}>
          <Input.Label htmlFor="prior_tax_payment_amount_lbl">
            Prior Tax Payment Amount
          </Input.Label>
          <Input.Currency
            id="priorTaxPaymentAmount"
            display={formik.values.currencyType}
            value={formik.values.priorTaxPaymentAmount}
            disabled={state.isDisplayOnlyActual}
            onChange={(event) => {
              formik.setFieldValue(
                'priorTaxPaymentAmount',
                event.target.value?.replace(/[^0-9.-]+/g, ''),
              )
            }}
            onBlur={(e) => {
              e.preventDefault()
              deriveFinalActualAmount(formik)
              isActualSummaryChanged()
            }}
            allowDecimals
          />
          {formik.errors.priorTaxPaymentAmount !== undefined &&
            formik.touched.priorTaxPaymentAmount && (
              <Input.Label
                htmlFor="prior_tax_payment_amount_err"
                className="hc-clr-error hc-mt-min"
              >
                {formik.errors.priorTaxPaymentAmount}
              </Input.Label>
            )}
        </Grid.Item>

        <Grid.Item xs={10} md={3}>
          <Input.Label htmlFor="final_due_amount_lbl">
            Final Due Amount
          </Input.Label>
          <Input.Currency
            id="finalDueAmount"
            display={formik.values.currencyType}
            value={formik.values.finalDueAmount}
            allowDecimals
            disabled
          />
        </Grid.Item>
      </Grid.Container>
    </Grid.Container>
  )
}

export default ViewActualAmountDetails
