import axios from 'axios'
import {
  Button,
  Form,
  Grid,
  Modal,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import * as Yup from 'yup'
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik'
import { useEnv } from '@praxis/component-runtime-env'
import { useAuth } from '@praxis/component-auth'
import { AdminContext } from '../../../globalConfig/AdminContext'
import { useContext } from 'react'
import { errorMsg } from '../../../globalConfig/common/ConstantData'

const handleClose = (props) => {
  props.setAddExpensePoolVisible(false)
}

export const AddExpensePool = (props) => {
  const env = useEnv()
  const { session } = useAuth()
  const makeToast = ToastProvider.useToaster()
  const context = useContext(AdminContext)

  const initialValues = {
    expenseType: '',
    expenseTypeName: '',
    expenseTypeDescr: '',
    glAccount: '',
    glAccountDescr: '',
  }

  const schema = Yup.object({
    expenseType: Yup.number().required('Please Enter Expense Type'),
    expenseTypeName: Yup.string().required('Please Enter Expense Type Name'),
    expenseTypeDescr: Yup.string().required('Please Enter Expense Type Descr'),
    glAccount: Yup.number().required('Please Enter GL Account'),
    glAccountDescr: Yup.string().required('Please Enter GL Account Descr'),
  })

  const onSubmit = (values) => {
    const submitheaders = {
      'content-type': 'application/json',
    }

    const expensePoolRequest = {
      expense_type: values.expenseType,
      expense_type_name: values.expenseTypeName,
      expense_type_descr: values.expenseTypeDescr,
      gl_account: values.glAccount,
      gl_account_descr: values.glAccountDescr,
      create_user_id: session.userInfo.lanId,
    }

    axios
      .post(
        `${env.camsCoreApiUrl}/expense_pool?key=${env.apiKey}`,
        expensePoolRequest,
        {
          headers: submitheaders,
        },
      )
      .then((res) => {
        makeToast({
          type: 'success',
          heading: 'Success',
          message: 'expense type is saved successfully',
        })
        console.log('data is saved for expense pool')
        formik.resetForm()
        context.fetchExpensePool()
        handleClose(props)
      })
      .catch((error) => {
        console.log('Error when saving expense pool data', error)
        const errorMessage = error.response?.data?.message || errorMsg
        makeToast({
          type: 'error',
          heading: 'Error',
          message: errorMessage,
        })
      })
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: schema,
    validate: (values) => {
      const validationSchema = schema
      try {
        validateYupSchema(values, validationSchema, true, values)
      } catch (err) {
        return yupToFormErrors(err)
      }
      return {}
    },
  })

  return (
    <Modal
      headingText="Create Expense Pool"
      isVisible={props.addExpensePoolVisible}
      onRefuse={() => {
        formik.resetForm()
        handleClose(props)
      }}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs={4} className="hc-pb-none">
              <Form.Field
                id={'expenseType'}
                label={'Expense Type'}
                type="number"
                onChange={(e) =>
                  formik.setFieldValue(
                    'expenseType',
                    e.target.value?.toUpperCase(),
                  )
                }
                value={formik.values.expenseType}
                className="inputtype hc-ta-left"
                error={
                  formik.touched.expenseType &&
                  formik.errors.expenseType !== undefined
                }
                errorText={formik.errors.expenseType}
              />
            </Grid.Item>

            <Grid.Item xs={4} className="hc-pb-none">
              <Form.Field
                id={'expenseTypeName'}
                label={'Expense Type Name'}
                type="text"
                onChange={(e) =>
                  formik.setFieldValue('expenseTypeName', e.target.value)
                }
                value={formik.values.expenseTypeName}
                error={
                  formik.touched.expenseTypeName &&
                  formik.errors.expenseTypeName !== undefined
                }
                errorText={formik.errors.expenseTypeName}
              />
            </Grid.Item>

            <Grid.Item xs={4} className="hc-pb-none">
              <Form.Field
                id={'expenseTypeDescr'}
                label={'Expense Type Descr'}
                type="text"
                onChange={(e) =>
                  formik.setFieldValue('expenseTypeDescr', e.target.value)
                }
                value={formik.values.expenseTypeDescr}
                error={
                  formik.touched.expenseTypeDescr &&
                  formik.errors.expenseTypeDescr !== undefined
                }
                errorText={formik.errors.expenseTypeDescr}
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>

        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs={4} className="hc-pb-none">
              <Form.Field
                id={'glAccount'}
                label={'Gl Account'}
                type="number"
                className="inputtype hc-ta-left"
                onChange={(e) =>
                  formik.setFieldValue('glAccount', e.target.value)
                }
                value={formik.values.glAccount}
                error={
                  formik.touched.glAccount &&
                  formik.errors.glAccount !== undefined
                }
                errorText={formik.errors.glAccount}
              />
            </Grid.Item>

            <Grid.Item xs={4} className="hc-pb-none">
              <Form.Field
                id={'glAccountDescr'}
                label={'Gl Account Descr'}
                type="text"
                onChange={(e) =>
                  formik.setFieldValue('glAccountDescr', e.target.value)
                }
                value={formik.values.glAccountDescr}
                error={
                  formik.touched.glAccountDescr &&
                  formik.errors.glAccountDescr !== undefined
                }
                errorText={formik.errors.glAccountDescr}
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item justify="flex-end" xs={12}>
              <Grid.Container direction="row-reverse" spacing="dense">
                <Grid.Item>
                  <Button type="submit">Save</Button>
                </Grid.Item>

                <Grid.Item>
                  <Button
                    onClick={() => {
                      formik.resetForm()
                      handleClose(props)
                    }}
                  >
                    Cancel
                  </Button>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Form>
    </Modal>
  )
}
