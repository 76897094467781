/** @jsxImportSource @emotion/react */
import axios from 'axios'
import { useEffect, useState, useRef, useContext } from 'react'
import {
  Grid,
  Button,
  Form,
  LayoutBody,
  ToastProvider,
  Dropdown,
} from '@enterprise-ui/canvas-ui-react'
import { useEnv } from '@praxis/component-runtime-env'
import { useGlobalForm } from '../../Context/GlobalFormStateContext'
import {
  ActualStatus,
  SET_FIELD,
  RESET,
  Workflow,
  STATUS,
  ACTUAL_STATUS,
  WORKFLOW_STATUS,
  ASSIGNEE_STATUS,
  PAM_SITEOPS_WORKFLOW,
  SITEOPS_WORKFLOW,
  ATTACHMENTS,
  REOPEN,
} from '../../../globalConfig/common/ConstantData'
import { BottomBorderDiv } from '../../Common/CamsDivComponent'
import { ViewActualAmountDetails } from '../../Common/ActualView/ViewActualAmountDetails'
import { ViewYearAndDocType } from '../../Common/ActualView/ViewYearAndDocType'
import { ViewVendorDetails } from '../../Common/ActualView/ViewVendorDetails'
import { ViewWorkflowDetails } from '../../Common/ActualView/ViewWorkflowDetails'
import { ViewDates } from '../../Common/ActualView/ViewDates'
import { ViewReopenDetails } from '../../Common/ActualView/ViewReopenDetails'
import { ViewLocationDetails } from '../../Common/ActualView/ViewLocationDetails'
import { ViewContractDetails } from '../../Common/ActualView/ViewContractDetails'
import { ViewAssignModal } from '../../Common/ActualView/ViewAssignModal'
import { useProfile } from '../../../globalComponents/UserProfileProvider'
import { editButtons } from '../../Common/CamsDivComponent'
import * as Yup from 'yup'
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik'
import { ViewWorkflowModal } from '../../Common/ActualView/ViewWorkflowModal'
import { NotesTab } from '../../Common/ActualView/NotesTab'
import { GenericFunctions } from '../../Utils/GenericFunctions'
import { ActualExpenses } from '../../Common/ActualExpenses'
import { CancelModal } from '../../Common/CancelModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { ViewUserQueryModal } from '../../Common/ActualView/ViewUserQueryModal'
import { LoadingSpinner } from '../../../globalComponents/LoadingSpinner'
import Attachments from '../../Attachment/Attachments'
import { useAttachmentRequest } from '../../hooks/useAttachmentRequest'
import { AttachmentContext } from '../../Context/AttachmentContext'
import ViewSaveTimelineModal from '../../Common/ViewSaveTimeLineModal'
import DownloadAttachments from '../../Common/ActualView/DownloadAttachments'
import moment from 'moment'
import { BreadCrumbActual } from '../../Common/BreadCrumbActual'
import { useNavigate } from 'react-router'
import DownloadActualSettlementReport from '../../Common/ActualView/DownloadActualSettlementReport'
import { ViewReopenModal } from '../../Common/ActualView/ViewReopenModal'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

const content = css`
  display: flex;
  justify-content: flex-start;
`

export const TMActualView = (props) => {
  const env = useEnv()
  const [state, dispatch] = useGlobalForm()
  const { hasAnyAdminRole, userProfile } = useProfile()
  const [actualUpdate, setActualUpdate] = useState('')
  const userName = userProfile.fullName
  const userId = userProfile.userId
  const showActivity = useRef(null)
  const makeToast = ToastProvider.useToaster()
  const navigate = useNavigate()
  const {
    addAuditEntryData,
    findRecentAnalyst,
    getExpenseTypes,
    getActualAmountDetails,
    deriveFinalActualAmount,
  } = GenericFunctions()

  const { uploadAttachments, getAttachmentForReqId } = useAttachmentRequest()
  const { uploadedAttachments } = useContext(AttachmentContext)

  useEffect(() => {
    fetchActual(props.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id])

  useEffect(() => {
    getExpenseTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    deriveFinalActualAmount(formik)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.totalAnnualAmount])

  const fetchAttachmentMetaData = async (requestId) => {
    await getAttachmentForReqId(requestId, 'Actual')
      .then((data) => {
        dispatch({
          type: SET_FIELD,
          payload: [
            {
              id: 'savedAttachments',
              value: data?.map(function (attachmentData) {
                return {
                  fileName: attachmentData.file_name,
                  documentType: attachmentData.document_type,
                  attachmentId: attachmentData.attachment_id,
                  createdBy: attachmentData.created_by,
                  createdTimestamp: moment(
                    attachmentData?.created_timestamp,
                  ).format('MM/DD/YYYY'),
                }
              }),
            },
          ],
        })
      })
      .catch((error) => {
        console.log('Error fetching attachment metadata', error)
      })
  }
  async function fetchActual(actualId) {
    // initialize actual expense before fetching actual data
    dispatch({
      type: SET_FIELD,
      payload: [
        { id: 'actualExpenses', value: [] },
        { id: 'actualDataLoaded', value: false },
      ],
    })
    await axios
      .get(`${env.camsCoreApiUrl}/actual/${actualId}?key=${env.apiKey}`)
      .then((res) => {
        checkRoleAndWorkflow(res.data)
        findDisplayOnly(res.data)

        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'actualData', value: res.data },
            { id: 'actualDataLoaded', value: true },
            { id: 'actualExpenses', value: res.data.actual_expenses },
          ],
        })
        fetchAttachmentMetaData(actualId)
        getExpenseTypes()
        assignActualSummary(res.data)
      })
      .catch((error) => {
        console.log('Error when fetching actual', error)
        makeToast({
          type: 'error',
          heading: 'Network Error',
          message: 'Something went wrong. Please try again later',
        })
        navigate(`/actual`)
        dispatch({
          type: SET_FIELD,
          payload: [{ id: 'actualDataLoaded', value: false }],
        })
      })
  }

  async function DeleteActualExpenseData() {
    const promiseArr = []
    if (state.deletedActualExpenses.length > 0) {
      for (var i = 0; i < state.deletedActualExpenses.length; i++) {
        const actualExpenseId = state.deletedActualExpenses[i].actual_expense_id
        let deleteUrl = `${env.camsCoreApiUrl}/actual/${actualExpenseId}?key=${env.apiKey}`
        promiseArr.push(
          axios.delete(deleteUrl).then((res) => {
            if (res.status === 204) {
              console.log(
                'actual expense id is deleted successfully , id=',
                actualExpenseId,
              )
            }
          }),
        )
      }
      Promise.all(promiseArr).then((done) => {
        makeToast({
          type: 'success',
          heading: 'Success',
          message: 'requested actual expenses are deleted successfully',
        })
        dispatch({
          type: SET_FIELD,
          payload: [
            {
              id: 'isActualDeleted',
              value: false,
            },
            {
              id: 'deletedActualExpenses',
              value: [],
            },
            {
              id: 'deletedActualExpense',
              value: {},
            },
          ],
        })
        dispatch({
          type: RESET,
        })
        fetchActual(state.actualData.actual_id)
      })
    }
  }

  function checkRoleAndWorkflow(actualData) {
    var hasRoleAndWorkflowMatch = false
    if (
      (userProfile.isAnalyst && actualData?.workflow === Workflow.PAA) ||
      (userProfile.isSiteOps && actualData?.workflow === Workflow.SITEOPS)
    ) {
      hasRoleAndWorkflowMatch = true
    }
    dispatch({
      type: SET_FIELD,
      payload: [
        { id: 'hasRoleAndWorkflowMatch', value: hasRoleAndWorkflowMatch },
      ],
    })
  }

  // finds whether display only access should be given
  const findDisplayOnly = (actualData) => {
    var hasRoleAndWorkflowMatch = false
    if (
      (userProfile.isAnalyst && actualData?.workflow === Workflow.PAA) ||
      (userProfile.isSiteOps && actualData?.workflow === Workflow.SITEOPS)
    ) {
      hasRoleAndWorkflowMatch = true
    }

    var displayOnly = false

    if (
      (userProfile.isAdmin || userProfile.isManager) &&
      (actualData?.status === ActualStatus.APPROVED ||
        (actualData?.status === ActualStatus.MORE_INFO_REQUIRED &&
          actualData?.workflow === Workflow.VENDOR))
    ) {
      displayOnly = true
    } else if (
      !hasRoleAndWorkflowMatch &&
      !userProfile.isAdmin &&
      !userProfile.isManager
    ) {
      displayOnly = true
    }
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'isDisplayOnlyActual', value: displayOnly }],
    })
  }

  const schema = Yup.object({
    // lanId: Yup.string().required('Please choose lanId'),
  })
  const formik = useFormik({
    initialValues: {},
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      const validationSchema = schema
      try {
        validateYupSchema(values, validationSchema, true, values)
      } catch (err) {
        return yupToFormErrors(err)
      }
      return {}
    },
    onSubmit: async (values) => {
      const adAuditEntry = addAuditEntryData(
        userId,
        userName,
        state,
        formik.values,
      )
      const submitheaders = {
        'content-type': 'application/json',
      }

      const updateActualRequest = {
        actual_id: state.actualData?.actual_id,
        doc_type: state.actualData?.doc_type,
        year: state.actualData?.year,
        contract_no: state.actualData?.contract_no,
        location_id: state.actualData?.location_id,
        loc_name: state.actualData?.loc_name,
        loc_address: state.actualData?.loc_address,
        loc_city: state.actualData?.loc_city,
        loc_state: state.actualData?.loc_state,
        contract_name: state.actualData?.contract_name,
        submitted_by_name: state.actualData?.submitted_by_name,
        submitted_by_email: state.actualData?.submitted_by_email,
        total_amount: state.isActualChanged
          ? state.grandTotalExpenseAmount
          : state.actualData?.total_amount,
        total_prs: state.isActualChanged
          ? state.totalPrsAmount
          : state.actualData?.total_prs,
        tgt_annual_amount: state.isActualChanged
          ? state.totalAnnualAmount
          : state.actualData?.tgt_annual_amount,
        tax_rate: values.taxRate,
        tax_amount: values.taxAmount,
        total_due_amount: values.totalDueAmount,
        prior_payment_amount: values.priorPaymentAmount,
        prior_tax_payment_amount: values.priorTaxPaymentAmount,
        final_due_amount: values.finalDueAmount,
        vendor_no: state.actualData?.vendor_no,
        vendor_name: state.actualData?.vendor_name,
        workflow:
          values?.workflow !== undefined
            ? values?.workflow
            : state.actualData?.workflow,
        status:
          values?.status !== undefined
            ? values?.status
            : state.actualData?.status,
        assigned_user_id:
          values?.lanId !== undefined
            ? values?.lanId
            : state.actualData?.assigned_user_id,
        assigned_user_name:
          values?.assignedUserName !== undefined
            ? values?.assignedUserName
            : state.actualData?.assigned_user_name,
        is_reopen:
          values?.isReopen !== undefined
            ? values?.isReopen
            : state.actualData?.is_reopen,
        reopen_reason:
          values?.reopenReason !== undefined
            ? values?.reopenReason
            : state.actualData?.reopen_reason,
        create_user_id: state.actualData?.create_user_id,
        create_ts: state.actualData?.create_ts,
        update_user_id: userProfile.userId,
        update_ts: state.actualData?.update_ts,
        actual_expenses: state.isActualChanged
          ? state.actualExpenses
          : state.actualData?.actual_expenses,
        actual_audit_entries: [adAuditEntry],
        actual_comments: values?.comments?.length !== 0 ? values?.comments : [],
      }

      //reset values
      formik.setFieldValue('comments', [])

      await axios
        .put(
          `${env.camsCoreApiUrl}/actual?key=${env.apiKey}`,
          updateActualRequest,
          {
            headers: submitheaders,
          },
        )
        .then((res) => {
          console.log('actual details are updated successfully')
          if (state.isActualDeleted) {
            DeleteActualExpenseData()
          } else {
            dispatch({
              type: RESET,
            })
            fetchActual(res.data.actual_id)
          }
          makeToast({
            type: 'success',
            heading: 'Success',
            message: actualUpdate,
          })
          setActualUpdate('')
        })
        .catch((error) => {
          console.log('Error when saving actual creation data', error)
          makeToast({
            type: 'error',
            heading: 'Network Error',
            message: 'Something went wrong. Please try again later',
          })
        })
    },
  })

  useEffect(() => {
    getActualAmountDetails(formik)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.actualExpenses])

  const assignActualSummary = (response) => {
    formik.setFieldValue('totalAmount', response.total_amount)
    formik.setFieldValue('totalAnnualAmount', response.tgt_annual_amount)
    formik.setFieldValue('taxRate', response.tax_rate)
    formik.setFieldValue('taxAmount', response.tax_amount)
    formik.setFieldValue('totalDueAmount', response.total_due_amount)
    formik.setFieldValue('priorPaymentAmount', response.prior_payment_amount)
    formik.setFieldValue(
      'priorTaxPaymentAmount',
      response.prior_tax_payment_amount,
    )
    formik.setFieldValue('finalDueAmount', response.final_due_amount)
  }

  const onAssignUser = () => {
    setActualUpdate(ASSIGNEE_STATUS)

    formik.setFieldValue('status', state.actualData.status)
    formik.setFieldValue('workflow', state.actualData.workflow)

    var adGroup = ''
    if (state.actualData.workflow === Workflow.PAA) {
      adGroup = env.auth.analystRole[0]
    } else if (state.actualData.workflow === Workflow.PAM) {
      adGroup = env.auth.managerRole[0]
    } else if (state.actualData.workflow === Workflow.SITEOPS) {
      adGroup = env.auth.siteOpsRole[0]
    }

    dispatch({
      type: SET_FIELD,
      payload: [
        { id: 'adGroup', value: adGroup },
        { id: 'showAssignModal', value: true },
      ],
    })
  }

  const handleSubmit = () => {
    setActualUpdate(ACTUAL_STATUS)
    if (state.isActualChanged) {
      const activityDescription = 'Actual expense details are modified'
      dispatch({
        type: SET_FIELD,
        payload: [{ id: 'activityDescr', value: activityDescription }],
      })
      // if actual is reopened and admin or manager submits the revised actual on behalf of vendor
      if (
        state.actualData?.workflow === Workflow.VENDOR &&
        state.actualData?.status === ActualStatus.REOPENED
      ) {
        formik.setFieldValue('status', ActualStatus.NOT_STARTED)
        formik.setFieldValue('workflow', Workflow.PAA)
        findRecentAnalyst(state.actualData.actual_audit_entries, formik)
      } else {
        // if revised actual is submitted by PAA, SITEOPS AND
        // ( Manager/Admin --> for all other statuses except Reopen)
        formik.setFieldValue('status', state.actualData.status)
        formik.setFieldValue('workflow', state.actualData.workflow)
        formik.setFieldValue('lanId', state.actualData?.assigned_user_id)
        formik.setFieldValue(
          'assignedUserName',
          state.actualData?.assigned_user_name,
        )
      }
    }
    formik.handleSubmit()
  }

  const handleStatusInProgressChange = () => {
    setActualUpdate(STATUS)
    formik.setFieldValue('status', ActualStatus.IN_PROGRESS)
    formik.setFieldValue('workflow', Workflow.PAA)
    formik.setFieldValue('lanId', state.actualData?.assigned_user_id)
    formik.setFieldValue(
      'assignedUserName',
      state.actualData?.assigned_user_name,
    )
    const activityDescription = `${'Changed the Actual Status to '}${
      ActualStatus.IN_PROGRESS
    }`
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'activityDescr', value: activityDescription }],
    })
    formik.handleSubmit()
  }

  const onManagerApproval = () => {
    setActualUpdate(STATUS)
    formik.setFieldValue('status', ActualStatus.APPROVED)
    formik.setFieldValue('workflow', Workflow.PAM)
    formik.setFieldValue('lanId', state.actualData?.assigned_user_id)
    const activityDescription = `${'Changed the Actual Status to '}${
      ActualStatus.APPROVED
    }`
    formik.setFieldValue(
      'assignedUserName',
      state.actualData?.assigned_user_name,
    )
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'activityDescr', value: activityDescription }],
    })
    formik.handleSubmit()
  }

  const onManagerDeny = () => {
    setActualUpdate(WORKFLOW_STATUS)
    formik.setFieldValue('status', ActualStatus.DENIED)
    formik.setFieldValue('workflow', Workflow.PAA)
    findRecentAnalyst(state.actualData.actual_audit_entries, formik)
    const activityDescription = `${'Changed the Actual Status to '}${
      ActualStatus.DENIED
    }`
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'activityDescr', value: activityDescription }],
    })
    formik.handleSubmit()
  }

  const onSiteOpsApproval = () => {
    setActualUpdate(WORKFLOW_STATUS)
    formik.setFieldValue('status', ActualStatus.APPROVED_BY_SITEOPS)
    formik.setFieldValue('workflow', Workflow.PAA)
    findRecentAnalyst(state.actualData.actual_audit_entries, formik)
    const activityDescription = `${'Changed the Actual Status to '}${
      ActualStatus.APPROVED_BY_SITEOPS
    }`
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'activityDescr', value: activityDescription }],
    })
    formik.handleSubmit()
  }

  const onMoreInfoRequiredFromVendor = () => {
    setActualUpdate(WORKFLOW_STATUS)
    formik.setFieldValue('status', ActualStatus.MORE_INFO_REQUIRED)
    formik.setFieldValue('workflow', Workflow.VENDOR)
    formik.setFieldValue('lanId', ' ') // Is it possible to assign to vendor / BP lan id? or leave it as blank?
    formik.setFieldValue('assignedUserName', ' ') // Is it possible to assign to vendor / BP name? or leave it as blank?
    const activityDescription = `${'Changed the Actual Status to '}${
      ActualStatus.MORE_INFO_REQUIRED
    }${' from Vendor'}`
    dispatch({
      type: SET_FIELD,
      payload: [
        { id: 'activityDescr', value: activityDescription },
        { id: 'showUserQueryModal', value: true },
      ],
    })
  }

  const onMoreInfoRequiredFromAnalyst = () => {
    setActualUpdate(WORKFLOW_STATUS)
    formik.setFieldValue('status', ActualStatus.MORE_INFO_REQUIRED)
    formik.setFieldValue('workflow', Workflow.PAA)
    findRecentAnalyst(state.actualData.actual_audit_entries, formik)
    const activityDescription = `${'Changed the Actual Status to '}${
      ActualStatus.MORE_INFO_REQUIRED
    }${' from PAA'}`
    dispatch({
      type: SET_FIELD,
      payload: [
        { id: 'activityDescr', value: activityDescription },
        { id: 'showUserQueryModal', value: true },
      ],
    })
  }

  // Reopen is allowed only for Admin and Manager
  const onReopen = () => {
    setActualUpdate(REOPEN)

    formik.setFieldValue('status', ActualStatus.REOPENED)
    formik.setFieldValue('workflow', Workflow.VENDOR)
    formik.setFieldValue('lanId', '')
    formik.setFieldValue('assignedUserName', '')

    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'showReopenModal', value: true }],
    })
  }

  const handleApprovalRequest = () => {
    setActualUpdate(WORKFLOW_STATUS)

    var workflow = ''
    if (
      state.actualData?.status === ActualStatus.IN_PROGRESS ||
      state.actualData?.status === ActualStatus.DENIED ||
      state.actualData?.status === ActualStatus.APPROVED_BY_SITEOPS
    ) {
      workflow = PAM_SITEOPS_WORKFLOW
    } else if (state.actualData?.status === ActualStatus.MORE_INFO_REQUIRED) {
      workflow = SITEOPS_WORKFLOW
    }

    dispatch({
      type: SET_FIELD,
      payload: [
        { id: 'actualWorkflow', value: workflow },
        { id: 'showWorkflow', value: true },
      ],
    })
  }

  const prepareToUploadAttachments = async () => {
    setActualUpdate(ATTACHMENTS)
    const activityDescription = 'Uploaded Attachment'
    formik.setFieldValue('status', state.actualData.status)
    formik.setFieldValue('workflow', state.actualData.workflow)
    formik.setFieldValue('lanId', state.actualData?.assigned_user_id)
    formik.setFieldValue(
      'assignedUserName',
      state.actualData?.assigned_user_name,
    )
    dispatch({
      type: SET_FIELD,
      payload: [
        { id: 'activityDescr', value: activityDescription },
        { id: 'viewAttachmentStatus', value: true },
      ],
    })

    uploadAttachments(props.id, state.actualData?.vendor_no, 'Actual')
  }

  return (
    <LayoutBody includeRail className="hc-pa-md">
      <Grid.Container>
        <Form>
          <Grid.Container>
            <BreadCrumbActual actualId={props.id} />
            <Grid.Item xs={12} className="hc-pb-none hc-pt-normal">
              <div css={content}>
                {(state.hasRoleAndWorkflowMatch ||
                  ((hasAnyAdminRole || userProfile.isManager) &&
                    state.actualData?.workflow !== Workflow.VENDOR &&
                    ((state.actualData?.status !== ActualStatus.APPROVED &&
                      state.actualData?.workflow === Workflow.PAM) ||
                      state.actualData?.workflow !== Workflow.PAM))) && (
                  <>
                    <Button
                      type="primary"
                      css={editButtons}
                      onClick={onAssignUser}
                    >
                      Assign
                    </Button>
                  </>
                )}

                {((state.hasRoleAndWorkflowMatch && userProfile.isAnalyst) ||
                  ((hasAnyAdminRole || userProfile.isManager) &&
                    state.actualData?.workflow === Workflow.PAA)) && (
                  <Button
                    type="primary"
                    css={editButtons}
                    onClick={onMoreInfoRequiredFromVendor}
                  >
                    More Info Required
                  </Button>
                )}

                {((state.hasRoleAndWorkflowMatch && userProfile.isAnalyst) ||
                  ((hasAnyAdminRole || userProfile.isManager) &&
                    state.actualData?.workflow === Workflow.PAA)) &&
                  state.actualData?.status === ActualStatus.NOT_STARTED && (
                    <Button
                      type="primary"
                      css={editButtons}
                      onClick={handleStatusInProgressChange}
                    >
                      Set to In Progress
                    </Button>
                  )}
                {((state.hasRoleAndWorkflowMatch && userProfile.isAnalyst) ||
                  ((hasAnyAdminRole || userProfile.isManager) &&
                    state.actualData?.workflow !== Workflow.VENDOR)) &&
                  (state.actualData?.status === ActualStatus.IN_PROGRESS ||
                    state.actualData?.status ===
                      ActualStatus.MORE_INFO_REQUIRED ||
                    state.actualData?.status === ActualStatus.DENIED ||
                    state.actualData?.status ===
                      ActualStatus.APPROVED_BY_SITEOPS) && (
                    <Button
                      type="primary"
                      css={editButtons}
                      onClick={handleApprovalRequest}
                    >
                      Submit for Approval
                    </Button>
                  )}

                {(hasAnyAdminRole || userProfile.isManager) &&
                  state.actualData?.workflow === Workflow.PAM &&
                  state.actualData?.status ===
                    ActualStatus.SUBMITTED_FOR_APPROVAL && (
                    <>
                      <Button
                        type="primary"
                        css={editButtons}
                        onClick={onManagerApproval}
                      >
                        Approve
                      </Button>
                      <Button
                        type="primary"
                        css={editButtons}
                        onClick={onManagerDeny}
                      >
                        Deny
                      </Button>
                    </>
                  )}

                {((state.hasRoleAndWorkflowMatch && userProfile.isSiteOps) ||
                  ((hasAnyAdminRole || userProfile.isManager) &&
                    state.actualData?.workflow === Workflow.SITEOPS)) &&
                  state.actualData?.status ===
                    ActualStatus.SUBMITTED_FOR_APPROVAL && (
                    <>
                      <Button
                        type="primary"
                        css={editButtons}
                        onClick={onSiteOpsApproval}
                      >
                        Approve
                      </Button>
                      <Dropdown size="dense" location="bottom-left">
                        <Button type="primary" css={editButtons}>
                          More Info Required{' '}
                          <FontAwesomeIcon
                            className="hc-ml-normal"
                            icon={faCaretDown}
                          />
                        </Button>
                        <Dropdown.Menu className="hc-bg-grey04">
                          <Dropdown.MenuItem
                            onClick={(e) => {
                              onMoreInfoRequiredFromAnalyst()
                            }}
                          >
                            From PAA
                          </Dropdown.MenuItem>
                          <Dropdown.MenuItem
                            onClick={(e) => {
                              onMoreInfoRequiredFromVendor()
                            }}
                          >
                            From Vendor
                          </Dropdown.MenuItem>
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  )}
                {(userProfile.isAdmin || userProfile.isManager) &&
                  state.actualData?.status === ActualStatus.APPROVED && (
                    <Button type="primary" css={editButtons} onClick={onReopen}>
                      Set to Reopen
                    </Button>
                  )}
                {state.actualData && <DownloadActualSettlementReport />}
              </div>
            </Grid.Item>
          </Grid.Container>
          <Grid.Container justify="space-between" className="hc-pa-none">
            <Grid.Item md={7} xs={12}>
              <ViewVendorDetails vendorDetails={state.actualData} />
              <ViewLocationDetails locationDetails={state.actualData} />
              <ViewContractDetails contractDetails={state.actualData} />
              {state.showAssignModal && (
                <ViewAssignModal
                  headingText="Assign the assignee?"
                  formik={formik}
                  saveAssignee={formik.handleSubmit}
                />
              )}

              {state.showWorkflow && (
                <ViewWorkflowModal
                  headingText="Assign the workflow?"
                  formik={formik}
                  saveWorkflow={formik.handleSubmit}
                />
              )}
            </Grid.Item>

            <Grid.Item md={5} xs={12} className="hc-pl-3x">
              <ViewYearAndDocType genericDetails={state.actualData} />
              <ViewWorkflowDetails
                workflowDetails={state.actualData}
                attachmentAvailable={state.savedAttachments?.length !== 0}
              />
              <ViewDates dates={state.actualData} />
              <ViewReopenDetails reopenDetails={state.actualData} />
            </Grid.Item>
          </Grid.Container>
          {state.actualDataLoaded ? (
            <Grid.Item xs={12}>
              <ActualExpenses expenseheadingText="Actual Expenses" />
              <br />
              <BottomBorderDiv className="hc-pb-expanded hc-pt-expanded">
                <ViewActualAmountDetails formik={formik} />
              </BottomBorderDiv>
              <Grid.Container
                direction="row-reverse"
                className="hc-pt-expanded"
              >
                <Grid.Item hidden={state.isSubmit}>
                  <Button
                    onClick={() => {
                      dispatch({
                        type: SET_FIELD,
                        payload: [{ id: 'isCancel', value: true }],
                      })
                    }}
                    disabled={state.actualCreatedFlag || !state.isActualChanged}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    type="primary"
                    disabled={!state.isActualChanged}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          ) : (
            <LoadingSpinner />
          )}
          <Grid.Item md={7} xs={12}>
            <DownloadAttachments />
          </Grid.Item>
          {state.actualData?.status !== ActualStatus.APPROVED && (
            <>
              <Attachments />
              <div className="hc-pa-normal hc-pb-dense hc-mt-lg">
                <Grid.Container direction="row-reverse">
                  <Grid.Item md={7} xs={12}>
                    <Button
                      size="dense"
                      type="primary"
                      onClick={prepareToUploadAttachments}
                      disabled={uploadedAttachments?.length === 0}
                    >
                      Save Attachments
                    </Button>
                  </Grid.Item>
                </Grid.Container>
              </div>
            </>
          )}
        </Form>

        {state.viewAttachmentStatus && (
          <ViewSaveTimelineModal
            saveAttachmentAuditEntry={formik.handleSubmit}
            redirectUrl={`/actual/${props.id}`}
            requestId={props.id}
            vendorNo={state.actualData?.vendor_no}
            docType="Actual"
          />
        )}
      </Grid.Container>
      <Grid.Container className="hc-pa-none hc-mt-2x">
        <Grid.Item md={7} xs={12}>
          {state.actualDataLoaded && (
            <div id="showActivity" ref={showActivity}>
              <Grid.Item className="hc-bg-white">
                <NotesTab
                  formik={formik}
                  actualDetails={state.actualData}
                  userProfile={userProfile}
                  saveComment={formik.handleSubmit}
                  setActualStatus={setActualUpdate}
                />
              </Grid.Item>
            </div>
          )}
        </Grid.Item>
      </Grid.Container>
      <ViewUserQueryModal
        headingText="Query"
        placeholderText="Enter your query here..."
        formik={formik}
        userProfile={userProfile}
        saveUserQuery={formik.handleSubmit}
      />
      <ViewReopenModal
        headingText="Reopen Actual"
        placeholderText="Enter Reopen Reason here..."
        formik={formik}
        saveReopenReason={formik.handleSubmit}
      />
      <CancelModal
        headingText="Cancel Actual Creation!"
        heading="Do you want to cancel the Actual Creation?"
      />
    </LayoutBody>
  )
}
