import {
  Grid,
  Timeline,
  ExpandableSection,
  Heading,
} from '@enterprise-ui/canvas-ui-react'
import '../../Actual/Custom.css'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import memoize from 'memoize-one'
import moment from 'moment'
import _ from 'lodash'
import { useGlobalForm } from '../../Context/GlobalFormStateContext'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

const activityColor = css`
  color: #3d70d6;
`

export const AuditLog = (auditEntries) => {
  const [state] = useGlobalForm()
  const formatDataForRendering = memoize((auditEntriesValues) => {
    let convertedData = _.groupBy(
      _.orderBy(auditEntriesValues, ['activity_ts'], ['desc']),
      (auditEntriesValues) => {
        return moment(auditEntriesValues.activity_ts).format('YYYY-MM-DD')
      },
    )
    return convertedData
  })

  const formattedData = formatDataForRendering(
    state.actualData.actual_audit_entries,
  )

  return (
    <Grid.Container className="hc-pt-normal hc-pl-normal">
      {Object.keys(formattedData).map((event, i) => {
        return (
          <Grid.Container className="hc-pt-normal hc-pl-normal">
            <ExpandableSection
              padding="none"
              startExpanded
              toggleLocation="left"
              className="hc-mb-none hc-mt-normal"
              key={i}
            >
              <Heading className="hc-clr-grey02 hc-txt-capitalize" size={6}>
                <DateFormatter date={event} size="dense" relative />
              </Heading>
              <ExpandableSection.Content className="hc-mt-none">
                <Timeline className="hc-clr-success">
                  {formattedData[event].map((row, index) => {
                    return (
                      <Timeline.Item key={index} className="hc-pb-none">
                        <Grid.Container>
                          <Grid.Item>
                            <p className="hc-clr-grey01 hc-fs-xs">
                              <DateFormatter
                                format="MM/DD/YYYY hh:mm A"
                                date={row?.activity_ts}
                              />
                              &nbsp;&nbsp;&mdash;
                            </p>
                          </Grid.Item>
                          <Grid.Item className="hc-pr-none hc-pl-none">
                            <p className="hc-fs-xs" css={activityColor}>
                              {row.activity_descr}
                            </p>
                          </Grid.Item>
                          <Grid.Item className="hc-pl-none hc-pr-none">
                            <p className="hc-clr-grey01 hc-fs-xs">
                              &nbsp;{'by'}&nbsp;
                            </p>
                          </Grid.Item>
                          <Grid.Item className="hc-pl-none hc-fs-xs">
                            <p css={activityColor}>{row.user_name}</p>
                          </Grid.Item>
                        </Grid.Container>
                      </Timeline.Item>
                    )
                  })}
                </Timeline>
              </ExpandableSection.Content>
            </ExpandableSection>
          </Grid.Container>
        )
      })}
    </Grid.Container>
  )
}
