import { NavLink } from 'react-router-dom'
import { Heading, SideNav, TargetLogo } from '@enterprise-ui/canvas-ui-react'
import {
  useProfile,
  ANALYST,
  MANAGER,
  SITEOPS,
  ADMIN,
  VENDOR,
} from './UserProfileProvider'

export const SideNavigation = ({ isOpen, onRequestClose }) => {
  const { userRole } = useProfile()
  return (
    <SideNav
      data-testid="sideNav"
      isVisible={true}
      onRequestClose={onRequestClose}
      className="hc-bg-grey05"
    >
      <SideNav.Header data-testid="sideNavLogoHeader" as={NavLink} to={'/'}>
        <TargetLogo size="expanded" className="hc-mr-dense" />
        <Heading size={1} className="hc-fs-md">
          CAM System
        </Heading>
      </SideNav.Header>
      <SideNav.Navigation data-testid="sideNavMainNavArea">
        {[ANALYST, MANAGER, SITEOPS, ADMIN, VENDOR].includes(userRole) && (
          <>
            <SideNav.NavigationItem as={NavLink} to={'/'} end>
              <Heading size={6}>Budget </Heading>
            </SideNav.NavigationItem>
            <SideNav.NavigationItem as={NavLink} to={'/actual'} end>
              <Heading size={6}>Actual </Heading>
            </SideNav.NavigationItem>
            <SideNav.NavigationItem as={NavLink} to={'/budget/create'} end>
              <Heading size={6}> Create Budget </Heading>
            </SideNav.NavigationItem>
            <SideNav.NavigationItem as={NavLink} to={'/actual/create'} end>
              <Heading size={6}> Create Actual </Heading>
            </SideNav.NavigationItem>
          </>
        )}
        {[ANALYST, MANAGER, SITEOPS, ADMIN].includes(userRole) && (
          <SideNav.NavigationGroup startExpanded>
            <SideNav.NavigationItem>
              <Heading size={6}>ADMIN</Heading>
            </SideNav.NavigationItem>
            <SideNav.NavigationGroupContent>
              {[ANALYST, MANAGER, SITEOPS, ADMIN].includes(userRole) && (
                <SideNav.NavigationItem
                  as={NavLink}
                  to={'/contractMetadata'}
                  end
                >
                  <Heading size={6}>Contract Metadata</Heading>
                </SideNav.NavigationItem>
              )}
              {[ANALYST, MANAGER, SITEOPS, ADMIN].includes(userRole) && (
                <SideNav.NavigationItem as={NavLink} to={'/expensePool'} end>
                  <Heading size={6}> Expense Pool</Heading>
                </SideNav.NavigationItem>
              )}
            </SideNav.NavigationGroupContent>
          </SideNav.NavigationGroup>
        )}
      </SideNav.Navigation>
    </SideNav>
  )
}
