import {
  Grid,
  Layout,
  Button,
  Heading,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { useState, useMemo, useEffect, useContext } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import EnterpriseIcon, { PencilIcon, TrashIcon } from '@enterprise-ui/icons'
import { AddExpensePool } from '../ExpensePool/AddExpensePool'
import { EditExpensePool } from './EditExpensePool'
import { DeleteExpensePool } from './DeleteExpensePool'
import { AdminContext } from '../../../globalConfig/AdminContext'
import { LoadingSpinner } from '../../../globalComponents/LoadingSpinner'
import { pageSizeOptions } from '../../../globalConfig/common/ConstantData'

export const ExpensePool = () => {
  const context = useContext(AdminContext)
  const [addExpensePool, setAddExpensePool] = useState(false)
  const [editExpensePool, setEditExpensePool] = useState(false)
  const [editableExpensePool, setEditableExpensePool] = useState({})
  const [deleteExpensePool, setDeleteExpensePool] = useState(false)
  const [deletableExpensePool, setDeletableExpensePool] = useState({})
  const [pageSize, setPageSize] = useState(10)

  const [rowData] = [context.expenseData]

  const handleAddExpensePool = () => {
    setAddExpensePool(true)
  }

  useEffect(() => {
    context.fetchExpensePool()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [columnDefs] = useState([
    {
      headerName: 'Edit',
      maxWidth: 70,
      filter: false,
      cellRenderer: (props) => {
        return (
          <Button
            iconOnly
            type="ghost"
            aria-label="Edit Icon"
            onClick={() => {
              setEditableExpensePool(props.data)
              setEditExpensePool(true)
            }}
          >
            <EnterpriseIcon icon={PencilIcon} />
          </Button>
        )
      },
    },
    {
      headerName: 'Delete',
      maxWidth: 70,
      filter: false,
      cellRenderer: (props) => {
        return (
          <Button
            iconOnly
            type="ghost"
            aria-label="Delete Icon"
            onClick={() => {
              setDeletableExpensePool(props.data)
              setDeleteExpensePool(true)
            }}
          >
            <EnterpriseIcon icon={TrashIcon} />
          </Button>
        )
      },
    },
    {
      headerName: 'Expense Type',
      field: 'expense_type',
      width: 142,
    },
    {
      headerName: 'Expense Type Name',
      field: 'expense_type_name',
      width: 200,
    },
    {
      headerName: 'Expense Type Description',
      field: 'expense_type_descr',
      width: 350,
    },
    {
      headerName: 'GL Account',
      field: 'gl_account',
      width: 142,
    },
    {
      headerName: 'GL Account Description',
      field: 'gl_account_descr',
      width: 350,
    },
  ])

  const defaultColDef = useMemo(
    () => ({
      // flex: 1,
      resizable: true,
      sortable: true,
      floatingFilter: true,
      filter: true,
      wrapText: true,
      autoHeight: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    }),
    [],
  )

  return (
    <>
      <Layout.SectionHeader>
        <div className="hc-pa-dense hc-pb-none">
          <Grid.Container justify="space-between">
            <Grid.Item>
              <Heading size={4}>Manage Expense Pool</Heading>
            </Grid.Item>
            <Grid.Item>
              <Grid.Container>
                <Grid.Item>
                  <Button type="secondary" onClick={handleAddExpensePool}>
                    Add Expense Type
                  </Button>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Layout.SectionHeader>

      <Layout.Body data-testid="layoutWithRightRailMainContent" includeRail>
        {!context.loadingExpensePool ? (
          <>
            <div>
              <Grid.Container>
                <Grid.Item className="hc-pt-md hc-pr-none">
                  <p>Page Size: </p>
                </Grid.Item>
                <Grid.Item xs={1} className="hc-pb-md hc-pl-sm hc-fs-xs">
                  <Input.Select
                    style={{ height: '25px' }}
                    id={'pageSize'}
                    label={'Page Size'}
                    defaultValue={10}
                    onUpdate={(id, value) => {
                      setPageSize(value)
                    }}
                    options={pageSizeOptions}
                  />
                </Grid.Item>
              </Grid.Container>
            </div>
            <Grid.Container>
              <div
                className="ag-theme-balham"
                style={{ height: '100%', width: '100%' }}
              >
                <AgGridReact
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  pagination={true}
                  paginationPageSize={pageSize}
                  rowHeight="25"
                  domLayout="autoHeight"
                ></AgGridReact>
              </div>
            </Grid.Container>
          </>
        ) : (
          <LoadingSpinner />
        )}
        <AddExpensePool
          addExpensePoolVisible={addExpensePool}
          setAddExpensePoolVisible={setAddExpensePool}
        ></AddExpensePool>
        <EditExpensePool
          editExpensePoolVisible={editExpensePool}
          setEditExpensePoolVisible={setEditExpensePool}
          data={editableExpensePool}
        ></EditExpensePool>
        <DeleteExpensePool
          deleteExpensePoolVisible={deleteExpensePool}
          setDeleteExpensePoolVisible={setDeleteExpensePool}
          data={deletableExpensePool}
        ></DeleteExpensePool>
      </Layout.Body>
    </>
  )
}
