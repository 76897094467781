import {
  Button,
  Form,
  Grid,
  Modal,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import * as Yup from 'yup'
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik'
import { useAuth } from '@praxis/component-auth'
import { SET_FIELD } from '../../globalConfig/common/ConstantData'
import { deriveBudgetAmount } from './CommonUtil'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useState } from 'react'
import _ from 'lodash'

const handleClose = (props) => {
  props.setAddBudgetExpenseVisible(false)
}

export const AddBudgetExpenses = (props) => {
  const { session } = useAuth()
  const [state, dispatch] = useGlobalForm()
  const [showOthersExpenseName, setShowOthersExpenseName] = useState(false)
  const [isSaveAndClose, setIsSaveAndClose] = useState(false)
  const [autoCompleteValue, setAutoCompleteValue] = useState(null)

  const initialValues = {
    expenseTypeDescr: '',
    expenseTypeName: '',
    expenseType: '',
    othersExpenseName: '',
    totalExpense: '',
    prs: '',
    prsAmount: '',
    adminFee: '',
    adminAmount: '',
    annualAmount: '',
    monthlyAmount: '',
  }

  const getExpenseType = async (expenseTypeDescr) => {
    if (expenseTypeDescr === 'Others') {
      setShowOthersExpenseName(true)
    } else {
      setShowOthersExpenseName(false)
    }

    let filteredExpenseTypeList = state.expensePool?.filter(
      (value) => value?.expense_type_descr === expenseTypeDescr,
    )
    if (filteredExpenseTypeList.length > 0) {
      formik1.setFieldValue(
        'expenseType',
        filteredExpenseTypeList[0].expense_type,
      )
      formik1.setFieldValue(
        'expenseTypeName',
        filteredExpenseTypeList[0].expense_type_name,
      )
      formik1.setFieldValue('expenseTypeDescr', expenseTypeDescr)
    } else {
      formik1.setFieldValue('expenseType', '')
      formik1.setFieldValue('expenseTypeName', '')
    }
  }

  const schema = Yup.object({
    expenseTypeDescr: Yup.string()
      .required('Please Enter Expense Type Descr')
      .test(
        'Fine',
        'Expense Type descr already Exists.Please choose some other descr',
        function (value) {
          var filteredData = state.budgetExpenses.filter((expense) => {
            if (
              showOthersExpenseName &&
              formik1.values.othersExpenseName !== '' &&
              formik1.values.othersExpenseName !== undefined
            ) {
              return (
                expense.expense_type_descr.toUpperCase() ===
                (value + '-' + formik1.values.othersExpenseName).toUpperCase()
              )
            } else {
              return expense.expense_type_descr === value
            }
          })
          if (filteredData.length > 0) {
            return false
          }
          return true
        },
      ),
    othersExpenseName: Yup.string().test(
      'Fine',
      'Please Enter Expense Name',
      function (value) {
        if (showOthersExpenseName) {
          if (value === '' || value === undefined) {
            return false
          } else {
            return true
          }
        }
        var filteredData = state.budgetExpenses.filter((expense) => {
          return expense.expense_type_descr === value
        })
        if (filteredData.length > 0) {
          return false
        }
        return true
      },
    ),
    totalExpense: Yup.number().required('Please Enter Total Expense'),
    prs: Yup.number()
      .required('Please Enter PRS %')
      .test('Fine', 'PRS should not be more than 100%', function (value) {
        if (value > 100) {
          return false
        }
        return true
      }),
    adminFee: Yup.number()
      .required('Please Enter Admin Fee %')
      .test('Fine', 'Admin Fee should not be more than 100%', function (value) {
        if (value > 100) {
          return false
        }
        return true
      }),
  })

  const onSubmit = (values) => {
    const newBudgetExpense = {
      expense_type_descr: showOthersExpenseName
        ? values.expenseTypeDescr + '-' + values.othersExpenseName
        : values.expenseTypeDescr,
      expense_type: values.expenseType,
      expense_type_name: values.expenseTypeName,
      total_expense_amount: Number(values.totalExpense),
      prs: Number(values.prs),
      prs_amount: Number(values.prsAmount),
      admin_fee: Number(values.adminFee),
      admin_amount: Number(values.adminAmount),
      annual_amount: Number(values.annualAmount),
      monthly_amount: Number(values.monthlyAmount),
      create_user_id: session.userInfo.lanId,
      create_ts: new Date().toISOString(),
      update_user_id: null,
      update_ts: null,
    }
    var budgetExpenses = [...state.budgetExpenses]

    budgetExpenses = [...budgetExpenses, newBudgetExpense]

    dispatch({
      type: SET_FIELD,
      payload: [
        {
          id: 'budgetExpenses',
          value: budgetExpenses,
        },
        {
          id: 'isBudgetChanged',
          value: true,
        },
      ],
    })
    formik1.resetForm()
    setIsSaveAndClose(false)
    if (isSaveAndClose) {
      setAutoCompleteValue(null)
      handleClose(props)
    }
  }

  const formik1 = useFormik({
    initialValues,
    onSubmit,
    validationSchema: schema,
    validate: (values) => {
      const validationSchema = schema
      try {
        validateYupSchema(values, validationSchema, true, values)
      } catch (err) {
        return yupToFormErrors(err)
      }
      return {}
    },
  })

  const debounceGetExpenseType = _.debounce((value) => {
    getExpenseType(value)
  }, 300)

  const handleUpdate = (id, value) => {
    setAutoCompleteValue(value)
    debounceGetExpenseType(value?.value)
  }

  return (
    <Modal
      headingText="Add Budget Expense"
      className="myModal"
      size="dense"
      isVisible={props.addBudgetExpenseVisible}
      onRefuse={() => {
        formik1.resetForm()
        setAutoCompleteValue(null)
        handleClose(props)
      }}
    >
      <Form onSubmit={formik1.handleSubmit}>
        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container justify="center">
            <Grid.Item xs={12} className="hc-pb-none">
              <Autocomplete
                id="expenseTypeDescr"
                options={state.expenseTypeOptions}
                filter={Autocomplete.filters.contains}
                label="Expense Type Descr"
                onUpdate={handleUpdate}
                value={autoCompleteValue}
                optionHeight="dense"
                error={
                  formik1.touched.expenseTypeDescr &&
                  formik1.errors.expenseTypeDescr !== undefined
                }
                errorText={formik1.errors.expenseTypeDescr}
              />
            </Grid.Item>
            {showOthersExpenseName && (
              <Grid.Item xs={12} className="hc-pb-none">
                <Form.Field
                  id={'othersExpenseName'}
                  label={'Expense Name'}
                  type="text"
                  onChange={(e) =>
                    formik1.setFieldValue('othersExpenseName', e.target.value)
                  }
                  onBlur={(e) => {
                    e.preventDefault()
                    deriveBudgetAmount(formik1)
                  }}
                  value={formik1.values.othersExpenseName || ''}
                  error={
                    formik1.touched.othersExpenseName &&
                    formik1.errors.othersExpenseName !== undefined
                  }
                  errorText={formik1.errors.othersExpenseName}
                />
              </Grid.Item>
            )}
          </Grid.Container>
        </Grid.Item>

        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Item xs={12} className="hc-pb-none">
            <Form.Field
              id={'expenseTypeName'}
              label={'Expense Type Name'}
              type="text"
              value={formik1.values.expenseTypeName || ''}
              disabled={true}
            />
          </Grid.Item>
        </Grid.Item>

        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container justify="center">
            <Grid.Item xs={6} className="hc-pb-none">
              <Form.Field
                id={'expenseType'}
                label={'Expense Type'}
                type="number"
                value={formik1.values.expenseType || ''}
                disabled={true}
              />
            </Grid.Item>
            <Grid.Item xs={6} className="hc-pb-none">
              <Input.Label htmlFor="total_expense_lbl">
                Total Expense
              </Input.Label>
              <Input.Currency
                id="totalExpense"
                display={formik1.values.currencyType}
                value={formik1.values.totalExpense}
                onChange={(event) => {
                  formik1.setFieldValue(
                    'totalExpense',
                    event.target.value?.replace(/[^0-9.-]+/g, ''),
                  )
                }}
                onBlur={(e) => {
                  e.preventDefault()
                  deriveBudgetAmount(formik1)
                }}
                allowDecimals
              />
              {formik1.errors.totalExpense !== undefined &&
                formik1.touched.totalExpense && (
                  <Input.Info htmlFor="total_expense_err" error={true}>
                    {formik1.errors.totalExpense}
                  </Input.Info>
                )}
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container justify="center">
            <Grid.Item xs={6} className="hc-pb-none">
              <Form.Field
                id={'prs'}
                label={'PRS (%)'}
                type="number"
                className="inputtype"
                onChange={(e) => formik1.setFieldValue('prs', e.target.value)}
                onBlur={(e) => {
                  e.preventDefault()
                  deriveBudgetAmount(formik1)
                }}
                value={formik1.values.prs || ''}
                error={formik1.touched.prs && formik1.errors.prs !== undefined}
                errorText={formik1.errors.prs}
              />
            </Grid.Item>
            <Grid.Item xs={6} className="hc-pb-none">
              <Input.Label htmlFor="prs_amount_lbl">PRS Amount</Input.Label>
              <Input.Currency
                id="prsAmount"
                display={formik1.values.currencyType}
                value={formik1.values.prsAmount}
                allowDecimals
                disabled
              />
            </Grid.Item>
            <Grid.Item xs={12} className="hc-pa-normal">
              <Grid.Container justify="center">
                <Grid.Item xs={6} className="hc-pb-none">
                  <Form.Field
                    id={'adminFee'}
                    label={'Admin Fee (%)'}
                    type="number"
                    className="inputtype"
                    onChange={(e) =>
                      formik1.setFieldValue('adminFee', e.target.value)
                    }
                    onBlur={(e) => {
                      e.preventDefault()
                      deriveBudgetAmount(formik1)
                    }}
                    value={formik1.values.adminFee || ''}
                    error={
                      formik1.touched.adminFee &&
                      formik1.errors.adminFee !== undefined
                    }
                    errorText={formik1.errors.adminFee}
                  />
                </Grid.Item>
                <Grid.Item xs={6} className="hc-pb-none">
                  <Input.Label htmlFor="admin_amount_lbl">
                    Admin Amount
                  </Input.Label>
                  <Input.Currency
                    id="adminAmount"
                    display={formik1.values.currencyType}
                    value={formik1.values.adminAmount}
                    allowDecimals
                    disabled
                  />
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
            <Grid.Item xs={12} className="hc-pa-normal">
              <Grid.Container justify="center">
                <Grid.Item xs={6} className="hc-pb-none">
                  <Input.Label htmlFor="annual_amount_lbl">
                    Annual Amount
                  </Input.Label>
                  <Input.Currency
                    id="annualAmount"
                    display={formik1.values.currencyType}
                    value={formik1.values.annualAmount}
                    allowDecimals
                    disabled
                  />
                </Grid.Item>

                <Grid.Item xs={6} className="hc-pb-none">
                  <Input.Label htmlFor="monthly_amount_lbl">
                    Monthly Amount
                  </Input.Label>
                  <Input.Currency
                    id="monthlyAmount"
                    display={formik1.values.currencyType}
                    value={formik1.values.monthlyAmount}
                    allowDecimals
                    disabled
                  />
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item justify="flex-end" xs={12}>
              <Grid.Container direction="row-reverse" spacing="dense">
                <Grid.Item>
                  <Button
                    type="submit"
                    onClick={() => {
                      setIsSaveAndClose(false)
                    }}
                  >
                    Save and Add More
                  </Button>
                </Grid.Item>

                <Grid.Item>
                  <Button
                    type="submit"
                    onClick={() => {
                      setIsSaveAndClose(true)
                    }}
                  >
                    Save and close
                  </Button>
                </Grid.Item>

                <Grid.Item>
                  <Button
                    onClick={() => {
                      formik1.resetForm()
                      setAutoCompleteValue(null)
                      handleClose(props)
                    }}
                  >
                    Cancel
                  </Button>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Form>
    </Modal>
  )
}
